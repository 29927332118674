export enum RoutePath {
  Dashboard = 'dashboard',
  Organisation = 'organisation',
  Toebs = 'toebs',
  Transaktionen = 'transaktionen',
  Nutzer = 'nutzer',
  News ='news',
  Einstellungen = 'einstellungen',
  Aufgaben = 'aufgaben',
  Vorgaenge = 'vorgaenge',
  AuftraggeberBeziehung = 'auftraggeber-beziehung',
  Auth = 'auth',
  Unauthorized = 'unauthorized'
}
