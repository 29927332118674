<div id="app-sidebar" class="">
  <nav class="gsa-nav" [class.gsa-nav--expanded]="navService.state.expanded()">
    <div class="gsa-nav__main">
      @for (item of navService.state.navigationItems(); track item.id) {
        @if (item.id === 'DIVIDER') {
          <hr/>
        } @else {
          <div class="gsa-nav-item" routerLinkActive="selected" [routerLink]="item.routerLink">
            <inf-icon [icon]="item.icon" size="md"/>
            <span>{{ item.label }}</span>
          </div>
        }
      }
      <div class="gsa-nav-item feedback" (click)="openFeedbackDialog()">
        <inf-icon icon="feedback" size="md"/>
        <span>Feedback</span>
      </div>
    </div>
    @if (navService.state.expanded()) {
      <div>
        <inf-sub-nav [navItems]="navService.state.navSubItems()" [title]="navService.state.navSubItems()"></inf-sub-nav>
      </div>
    }
  </nav>
</div>
