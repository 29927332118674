const countryCodeMap = {
  AT: true,
  BE: true,
  BG: true,
  BRD: true,
  CY: true,
  CZ: true,
  D: true,
  DE: true,
  DK: true,
  EE: true,
  ES: true,
  FI: true,
  FR: true,
  GR: true,
  HR: true,
  HU: true,
  IE: true,
  IT: true,
  LT: true,
  LU: true,
  LV: true,
  MT: true,
  NL: true,
  PL: true,
  PT: true,
  RO: true,
  SE: true,
  SI: true,
  SK: true
};

export default countryCodeMap;
