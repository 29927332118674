import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { Feedback } from '@components/feedback/feedback.type';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  constructor(private http: HttpClient) {}

  sendFeedback(url: string, userName: string, feedback: Feedback): Observable<any> {
    const body = { url, userName, feedback };
    return this.http.post(`${environment.apiUrl}/api/v1/feedback/teams`, body);
  }
}

