import {
  ChangeDetectorRef,
  Compiler,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewContainerRef
} from '@angular/core';
import { RowEvent, TableRow } from '../models/table-row.model';
import { TableColumn } from '../models/table-column.model';
import type { TableComponent } from '../components/table.component';

export interface DynamicCell {
  row: TableRow;
  column: TableColumn<any>;
  parent: TableComponent<any>;
  onRowEvent?: EventEmitter<RowEvent>;
}

@Directive({
  selector: '[dynamicCell]'
})
export class DynamicCellDirective implements OnInit, OnChanges, OnDestroy {
  @Input() component: any;
  @Input() column: TableColumn<any>;
  @Input() row: any;
  @Input() onRowEvent: EventEmitter<RowEvent>;
  componentRef: ComponentRef<DynamicCell> = null;

  constructor(
    public compiler: Compiler,
    private cfr: ComponentFactoryResolver,
    private vc: ViewContainerRef,
    private cd: ChangeDetectorRef,
    @Inject('DYNAMIC_CELL') private parent: TableComponent<any>) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.componentRef === null || this.componentRef === undefined) {
      this.initComponent();
    }
    // pass input parameters
    if (changes['column'] && changes['column'].currentValue) {
      this.componentRef.setInput('column', this.column);
    }
    if (changes['row'] && changes['row'].currentValue) {
      this.componentRef.destroy();
      this.initComponent();
      this.componentRef.setInput('row', this.row);
    }
    if (changes['onRowEvent'] && changes['onRowEvent'].currentValue) {
      this.componentRef.setInput('onRowEvent', this.onRowEvent);
    }
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  initComponent() {
    try {
      this.componentRef = this.vc.createComponent(this.component);
      this.updateInput();
    } catch (e) {
      console.warn(e);
    }
  }

  updateInput() {
    if (this.parent) {
      (this.componentRef.instance as DynamicCell).parent = this.parent;
    }
    if (this.column) {
      this.componentRef.instance.column = this.column;
    }
    if (this.row) {
      (this.componentRef.instance as DynamicCell).row = this.row;
    }
    if (this.onRowEvent) {
      (this.componentRef.instance as DynamicCell).onRowEvent = this.onRowEvent;
    }
  }
}
