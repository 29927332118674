import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { FieldType, FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-note',
  imports: [CommonModule, MatIconModule],
  template: `
    <div [ngClass]="[noteType, 'container', disabled ? 'disabled' : '']">
      <mat-icon>{{ icon }}</mat-icon>
      <div>
        <div *ngIf="title" class="title">{{ title }}</div>
        <div [innerHTML]="content"></div>
      </div>
    </div>
  `,
  styles: [
    `
      @use '../../../styles/base' as inf;

      .container {
        border-radius: 4px;
        display: flex;
        font-size: inf.$font-size-small;
        gap: 18px;
        max-width: 100vw;
        min-width: 240px;
        padding: 16px;
        margin-bottom: 12px;
      }

      mat-icon {
        height: 20px;
        width: 20px;
        font-size: 20px;
      }

      .title {
        font-weight: 500;
        padding-bottom: 8px;
      }
      .highlight {
        border: 1px solid inf.$infrest-highlight-border-color;
        background-color: inf.$infrest-highlight-background-color;
        mat-icon {
          color: inf.$infrest-primary-variant-700;
        }
      }

      .warning {
        border: 1px solid inf.$infrest-warning-border-color;
        background-color: inf.$infrest-warning-background-color;
        mat-icon {
          color: inf.$infrest-warning-color-dark;
        }
      }

      .critical {
        border: 1px solid inf.$infrest-error-color;
        background-color: inf.$infrest-error-background-color;
        mat-icon {
          color: inf.$infrest-error-color-dark;
        }
      }

      .disabled {
        opacity: 0.45;
      }
    `
  ],
  standalone: true
})
export class NoteComponent implements OnInit {
  @Input() noteType = 'highlight' || 'critical' || 'warning';
  @Input() title: string;
  @Input() content: string;
  disabled = false;
  icon: string;

  constructor() {}

  ngOnInit(): void {
    switch (this.noteType) {
      case 'highlight':
        this.icon = 'info_outline';
        break;
      case 'critical':
        this.icon = 'warning_amber';
        break;
      case 'warning':
        this.icon = 'notifications_active';
        break;
      default:
        this.icon = 'info_outline';
    }
  }
}

@Component({
  selector: 'formly-note',
  imports: [CommonModule, MatIconModule],
  template: `
    <div [ngClass]="[noteType, 'container', disabled ? 'disabled' : '']">
      <mat-icon>{{ icon }}</mat-icon>
      <div>
        <div *ngIf="title" class="title">{{ title }}</div>
        <div [innerHTML]="content"></div>
      </div>
    </div>
  `,
  styles: [
    `
      @use '../../../styles/base' as inf;

      .container {
        border-radius: 4px;
        display: flex;
        font-size: inf.$font-size-small;
        gap: 18px;
        max-width: 100vw;
        min-width: 240px;
        padding: 16px;
        margin-bottom: 12px;
      }

      mat-icon {
        height: 20px;
        width: 20px;
        font-size: 20px;
        overflow: visible;
      }

      .title {
        font-weight: 500;
        padding-bottom: 8px;
      }
      .highlight {
        border: 1px solid inf.$infrest-highlight-border-color;
        background-color: inf.$infrest-highlight-background-color;
        mat-icon {
          color: inf.$infrest-primary-variant-700;
        }
      }

      .warning {
        border: 1px solid inf.$infrest-warning-border-color;
        background-color: inf.$infrest-warning-background-color;
        mat-icon {
          color: inf.$infrest-warning-color-dark;
        }
      }

      .critical {
        border: 1px solid inf.$infrest-error-color;
        background-color: inf.$infrest-error-background-color;
        mat-icon {
          color: inf.$infrest-error-color-dark;
        }
      }

      .disabled {
        opacity: 0.45;
      }
    `
  ],
  standalone: true
})
export class FormlyNoteComponent extends FieldType<FieldTypeConfig> implements OnInit {
  title: string = '';
  content: string = '';
  noteType: string = '';
  disabled = false;
  icon: string;

  ngOnInit() {
    this.title = this.to['title'];
    this.content = this.to['content'];
    this.noteType = this.to['noteType'] || 'highlight';
    this.disabled = this.to['disabled'];

    switch (this.to['noteType']) {
      case 'highlight':
        this.icon = 'info_outline';
        break;
      case 'critical':
        this.icon = 'warning_amber';
        break;
      case 'warning':
        this.icon = 'notifications_active';
        break;
      default:
        this.icon = 'info_outline';
    }
  }
}
