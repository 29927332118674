<div class="inf-cockpit">
  @if (loginDisplay) {
    @if (isAuthorized) {
      <inf-nav/>
      <inf-app-header></inf-app-header>
      <main class="content" [style.max-width]="maxWidth()">
        <router-outlet></router-outlet>
      </main>
    } @else {
      <inf-app-header [displayOnly]="!isAuthorized"></inf-app-header>
      <router-outlet></router-outlet>
    }
  }
</div>
