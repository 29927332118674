import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { TableService } from '../../services/table.service';
import { TableSetting } from '../../models/table-settings.model';
import { AbstractColumn } from '../../models/table-column.model';
import { TableIntl } from '../../core/table-intl';
import { deepClone } from '../../utils';
import { isNullOrUndefined } from '../../core/types';
import { distinctUntilChanged, Subscription } from 'rxjs';
import { TableColumnSettingsComponent } from '@components/table/extensions/column-settings/column-settings.component';
import { MatDialog } from '@angular/material/dialog';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'inf-table-menu',
  templateUrl: './table-menu.component.html',
  styleUrls: ['./table-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableMenuComponent implements OnDestroy {
  @Output() menuActionChange: EventEmitter<TableMenuActionChange> = new EventEmitter<TableMenuActionChange>();

  @Input() visible: boolean = false;
  @Input()
  get tableSetting(): TableSetting {
    return this.currentTableSetting;
  }

  set tableSetting(value: TableSetting) {
    value.settingsList = value.settingsList === undefined ? [] : value.settingsList;
    this.originalTableSetting = value;
    // this.reverseDirection = value.direction === 'rtl' ? 'ltr' : 'rtl';
    this.currentTableSetting = value;
  }

  @Output() tableSettingChange = new EventEmitter<TableSetting>();
  @ViewChild('newSetting', { static: false }) newSettingElement: ElementRef;

  newSettingName = '';
  showNewSetting = false;

  currentColumn: number = null;
  reverseDirection: string = null;
  originalTableSetting: TableSetting;
  currentTableSetting: TableSetting;
  dialogSubscription: Subscription = new Subscription();

  constructor(
    public dialog: MatDialog,
    public languagePack: TableIntl,
    public tableService: TableService
  ) {}

  screenMode_onClick() {
    this.menuActionChange.emit({
      type: 'FullScreenMode',
      data: this.currentTableSetting
    });
  }

  /***** Column Setting ******/
  columnMenuDropped(event: CdkDragDrop<any>): void {
    moveItemInArray(this.currentTableSetting.columnSetting, event.item.data.columnIndex, event.currentIndex);
  }

  toggleSelectedColumn(column: AbstractColumn) {
    // const colFound = this.currentTableSetting.columnSetting.find(c => c === column);
    column.display = column.display === 'visible' ? 'hidden' : 'visible';
  }

  apply_onClick(e) {
    e.stopPropagation();
    e.preventDefault();
    this.menuActionChange.emit({
      type: 'TableSetting',
      data: this.currentTableSetting
    });
    this.tableService.saveColumnInfo(this.currentTableSetting.columnSetting);
  }

  setting_onClick(i) {
    this.currentColumn = i;
  }

  cancel_onClick() {
    this.currentTableSetting = deepClone(this.originalTableSetting);
  }

  isVisible(visible: boolean) {
    return isNullOrUndefined(visible) ? true : visible;
  }

  /*****  Save ********/
  saveSetting_onClick(e, setting) {
    e.stopPropagation();
    this.menuActionChange.emit({
      type: 'SaveSetting',
      data: setting?.settingsName
    });
  }

  newSetting_onClick(e) {
    this.showNewSetting = true;
    this.newSettingName = '';
    window.requestAnimationFrame(() => {
      this.newSettingElement.nativeElement.focus();
    });
    e.stopPropagation();
  }

  selectSetting_onClick(e, setting: TableSetting) {
    e.stopPropagation();
    this.menuActionChange.emit({
      type: 'SelectSetting',
      data: setting?.settingsName
    });
  }

  default_onClick(e, setting) {
    e.stopPropagation();
    this.menuActionChange.emit({
      type: 'DefaultSetting',
      data: setting.settingName
    });
  }

  applySaveSetting_onClick(e) {
    e.stopPropagation();
    this.menuActionChange.emit({
      type: 'SaveSetting',
      data: this.newSettingName
    });
    this.showNewSetting = false;
  }

  cancleSaveSetting_onClick(e) {
    e.stopPropagation();
    this.newSettingName = '';
    this.showNewSetting = false;
  }

  deleteSetting_onClick(e, setting) {
    e.stopPropagation();
    this.menuActionChange.emit({ type: 'DeleteSetting', data: setting });
    this.newSettingName = '';
    this.showNewSetting = false;
  }

  clearFilter_onClick() {
    setTimeout(() => {
      this.menuActionChange.emit({ type: 'FilterClear' });
    });
  }

  download_onClick(type: string) {
    setTimeout(() => {
      this.menuActionChange.emit({ type: 'Download', data: type });
    });
  }

  print_onClick(menu) {
    menu._overlayRef._host.parentElement.click();
    setTimeout(() => {
      this.menuActionChange.emit({ type: 'Print', data: null });
    });
  }

  columnSettings_onClick(e, setting) {
    e.stopPropagation();

    const dialog = this.dialog.open<TableColumnSettingsComponent, any, any>(TableColumnSettingsComponent, {
      width: '60vw',
      height: '60vh',
      data: {
        columnSetting: this.currentTableSetting.columnSetting
      }
    });

    this.dialogSubscription = dialog
      .afterClosed()
      .pipe(
        filter(result => result?.action === 'save'),
        distinctUntilChanged()
      )
      .subscribe(async res => {
        this.menuActionChange.emit({
          type: 'TableSetting',
          data: this.currentTableSetting
        });
        this.tableService.saveColumnInfo(this.currentTableSetting.columnSetting);
      });
  }

  ngOnDestroy() {
    this.dialogSubscription.unsubscribe();
  }
}

export interface TableMenuActionChange {
  type: 'FilterClear' | 'TableSetting' | 'Download' | 'SaveSetting' | 'DeleteSetting' | 'SelectSetting' | 'DefaultSetting' | 'Print' | 'FullScreenMode';
  data?: any;
}
