import { Component, OnInit } from '@angular/core';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';
import { IFile } from './models';
import { EMPTY, forkJoin, Observable, of } from 'rxjs';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
import { FileUploadService } from './services/file-upload.service';
import { SubSink } from 'subsink';
import { MatSnackBar } from '@angular/material/snack-bar';

type UploadProgressInfo = { value: number; fileName: string };
export type FilesReadyInfo = {
  files: IFile[];
  anyDocTypesMissing: boolean;
  performUpload: () => Observable<unknown[]>
};

@Component({
  selector: 'inf-formly-file-upload-type',
  template: `
    <div class="file-upload-wrapper">
      <span class="file-upload-title">{{ props.label }}{{ to.required ? '*' : '' }}</span>

      <div infFileDragAndDrop (infFileDragAndDrop)="onDrop($event)" class="file-upload-container">
        <div class="justify-content-center file-upload-inner-container padding-bottom-2">
            <span class="padding-1 margin-top-4 head-text">
                Legen Sie ihre Datei hier ab oder klicken Sie auf Datei auswählen, um eine Datei von Ihrem PC auszuwählen.
            </span>
          <div>
            <button (click)="fileInput.click()" color="primary" mat-raised-button>Datei auswählen</button>
            <input
              #fileInput
              [multiple]="false"
              [accept]="props['fileType']"
              [formControl]="formControl"
              [formlyAttributes]="field"
              (change)="selectFiles($event)"
              onclick="this.value=null;"
              style="display: none"
              type="file" />
          </div>

          <ng-container *ngIf="filesStillUploading">
            <div *ngFor="let progressInfo of progressInfos" class="file-progress-container">
              <div *ngIf="progressInfo?.value < 100 && progressInfo?.value > 0">
                <mat-progress-bar [value]="progressInfo?.value" mode="determinate"></mat-progress-bar>
                <span>{{ progressInfo?.fileName }}</span>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="files">
            <ng-container *ngFor="let file of files; let i = index">
              <table style="width: 100%;margin-top: 12px;">
                <tr>
                  <th>Dateiname</th>
                  <th>Dateityp</th>
                  <th>Große</th>
                  <th></th>
                  <th></th>
                </tr>
                <tr>
                  <td><span matSuffix>{{ file.name }}</span></td>
                  <td><span matSuffix>{{ file.format }}</span></td>
                  <td><span matSuffix>{{ file.size | formatFileSize }}</span></td>
                  <td>
                    <button *ngIf="file.isOnline" mat-stroked-button (click)="downloadFile(file.id)">
                      <mat-icon aria-hidden="false" aria-label="Download button">download</mat-icon>
                    </button>
                  </td>
                  <td>
                    <button mat-mini-fab color="warn" (click)="removeFile(file)">
                      <mat-icon aria-hidden="false" aria-label="Remove selected file button">delete</mat-icon>
                    </button>
                  </td>
                </tr>
              </table>
            </ng-container>
          </ng-container>
          <div class="fileSizeExceedsMaxWarn" *ngIf="fileSizeExceedsMax">
            Die Upload-Größe überschreitet das erlaubte Maximum von {{ overallMaxFileSize | formatFileSize }}. Bitte
            wählen Sie weniger oder
            kleinere Dateien zum Upload aus.
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [`
    @use '../../../../styles/base' as inf;

    .file-upload-wrapper {
      margin-top: 12px;

      .file-upload-title {
        font-size: 14px;
        font-weight: 500;
      }

      .file-upload-container {
        font-size: 14px;
        border: 2px dashed #9e9e9e;
        padding: 16px;
        overflow: auto;

        &:hover {
          /* Primary/500 - Accent */
          border: 2px dashed #18a8e5;
        }

        .file-upload-inner-container {
          align-items: center;
          text-align: center;

          .head-text {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: inf.$font-size-small;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.5px;
            color: inf.$infrest-black-high-emphasize;
            width: 497px;
          }

          .sub-text {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: inf.$font-size-small;
            line-height: 21px;
            text-align: center;
            letter-spacing: 0.25px;
            color: inf.$infrest-black-medium-emphasize;
          }
        }
      }
    }

    button {
      max-width: 154px;
      margin: 0.5em;
    }

    .file-progress-container {
      width: 90%;
      margin: 2em 0 0 0;
      text-align: left;

      span {
        font-size: 75%;
      }
    }

    .drop-zone-active {
      /* Primary/500 - Accent */
      border: 2px dashed #18a8e5;
    }

    button {
      max-width: 154px;
      margin: 0.5em;
    }
  `]
})
export class FileUploadTypeComponent extends FieldType<FieldTypeConfig> implements OnInit {
  overallMaxFileSize = 10 * 1024 * 1024;
  progressInfos: UploadProgressInfo[] = [];
  files: IFile[] = [];
  subs = new SubSink();

  constructor(private fileUploadService: FileUploadService, private readonly snackBar: MatSnackBar) {
    super();
    this.removeFile = this.removeFile.bind(this);
  }

  ngOnInit() {
    this.overallMaxFileSize = this.props['maxFileSize'] || this.overallMaxFileSize;
  }

  get filesStillUploading(): boolean {
    return this.progressInfos.some(progressInfo => progressInfo.value !== undefined && progressInfo.value < 100);
  }

  get filesSizeSum(): number {
    return this.files?.reduce((sum, file) => sum + file.size, 0);
  }

  get fileSizeExceedsMax(): boolean {
    return this.filesSizeSum > this.overallMaxFileSize;
  }

  public onDrop(files: File[]) {
    this.validateOnAddFiles(files);
  }

  public selectFiles(event: Event): void {
    const inputEl = event.target as HTMLInputElement;
    this.validateOnAddFiles(Array.from(inputEl.files));
  }

  private validateOnAddFiles(addedFiles: File[]): void {
    const newFiles = addedFiles.map(addedFile => ({
      id: uuidv4(),
      name: addedFile.name.substring(0, addedFile.name.lastIndexOf('.')),
      format: (addedFile.name.indexOf('.') > -1 ? addedFile.name.substring(addedFile.name.lastIndexOf('.')) : '').toLocaleUpperCase(),
      file: addedFile,
      size: addedFile.size,
      isValid:
        !this.files.some(selectedFile => {
          if (selectedFile.name === addedFile.name) {
            this.showSnackBar('alreadyExists', addedFile.name);
          }
          return selectedFile.name === addedFile.name;
        }) && !!(this.containsOnlyValidChars(addedFile.name) || this.showSnackBar('specialChars', addedFile.name)),
      hasChanged: false,
      isOnline: false,
      type: ''
    }));

    this.progressInfos = [];

    // Only allow single file for now
    // this.files = [...this.files, ...newFiles];
    this.files = [...newFiles];

    // Convert value to FileList
    this.value = newFiles.map(file => file.file);

    this.emitFilesReady();
  }

  upsertFiles() {
    return forkJoin(
      this.files
        // .filter(file => file.hasChanged && file.type)
        .map((file, i) => {
          //changing file name after being uploaded would need a bigger change in BE, so for now will be disabled
          // if (file.hasChanged && file.isOnline) {
          //   //currently just returns empty
          //   return this.fileUploadService.updateFile$(this.vorgangId, file);
          // }
          if (!file.isOnline) {
            return this.upload(i, file.name, file.file, file.id);
          }
          return of(null);
        })
    );
  }

  fileNameValidator: ValidatorFn = (control: AbstractControl) => {
    // any component logic here
    if (this.files.filter(file => file.name === control.value).length > 1) {
      control.markAsTouched();
      return { sameName: true };
    } else if (!this.containsOnlyValidChars(control.value)) {
      control.markAsTouched();
      return { specialChars: true };
    }
    return null;
  };

  typeValidator: ValidatorFn = (control: AbstractControl) => {
    if (control.value === '') {
      control.markAsTouched();
      return { noType: true };
    }
    return {};
  };

  downloadFile(fileId: string) {
    this.subs.sink = this.fileUploadService.getFileForDownload(fileId).subscribe((resp: any) => {
      const dispositionHeader = resp.headers.get('content-disposition');
      const firstIndex = dispositionHeader.indexOf('"') + 1;
      const lastIndex = dispositionHeader.lastIndexOf('"');
      const blob = new Blob([resp.body], { type: resp.type });
      const downloadURL = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadURL;
      link.download = dispositionHeader.slice(firstIndex, lastIndex);
      link.click();
    });
  }

  removeFile(file: IFile): void {
    const showFileRemovedMessage = () => this.snackBar.open(`Datei '${file.name}' entfernt`, 'Schließen');
    const selectedFileIndex = this.files.findIndex(f => f.id === file.id);

    if (selectedFileIndex >= 0) {
      showFileRemovedMessage();
      this.files.splice(selectedFileIndex, 1);
      if (file.isOnline) {
        file.isDeleted = true;
      }
    }

    this.value = this.files.length > 0 ? this.files[0] : null;

    this.emitFilesReady();
  }

  private containsOnlyValidChars(fileName: string): boolean {
    const format = /^[\w \-\.]*$/; // any word character [a-zA-Z0-9_ ], - or .
    return format.test(fileName);
  }

  private showSnackBar(error: 'alreadyExists' | 'specialChars', fileName: string): void {
    let message = '';
    switch (error) {
      case 'alreadyExists':
        message = `Achtung: Upload wurde abgebrochen. Beachten Sie die Hinweise zu Uploadgröße und Namenskonventionen. Die Datei mit dem Namen "${fileName}" ist bereits vorhanden.`;
        break;
      case 'specialChars':
        message = `Achtung: Upload wurde abgebrochen. Beachten Sie die Hinweise zu Uploadgröße und Namenskonventionen. Die Datei mit dem Namen "${fileName}" enthält unerlaubte Sonderzeichen.`;
        break;
    }
    // this.snackBar.open(message, 'Schließen');
  }

  private upload(idx: number, fileName: string, file: File, fileId: string) {
    this.progressInfos[idx] = { value: undefined, fileName: fileName };

    if (file) {
      // return this.fileUploadService.uploadVorgangFile(file, fileId, this.vorgangId).pipe(
      //   // skipUntil(timer(2000)),
      //   tap(progress => (this.progressInfos[idx].value = Math.round((100 * progress.loaded) / progress.total))),
      //   catchError(err => {
      //     this.progressInfos[idx].value = 0;
      //     throw err;
      //   })
      // );
    }
    return EMPTY;
  }

  private validateDocuments(): boolean {
    this.snackBar.dismiss();

    const isValidAndUniqueNames = this.files.every(file => {
      const isValid = this.containsOnlyValidChars(file.name);
      const isUnique = this.files.filter(otherFile => otherFile.name === file.name).length === 1;

      return isValid && isUnique;
    });

    if (!isValidAndUniqueNames) {
      return false;
    }

    if (this.fileSizeExceedsMax) {
      return false;
    }

    return true;
  }

  private emitFilesReady() {
    // this.filesReady.emit({
    //   files: this.files,
    //   anyDocTypesMissing: !this.validateDocuments(),
    //   performUpload: () => this.upsertFiles()
    // });
  }
}
