import { Component, ElementRef, inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { icons } from './icons';

export type IconSize = 'ti' | 'sm' | 'rg' | 'md' | 'lg' | 'xxl';
export type IconType = keyof typeof icons;

@Component({
  selector: 'inf-icon',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit, OnChanges {
  @Input() icon: IconType = 'account';
  @Input() size: IconSize = 'rg';
  @Input() asButton = false;
  @Input() spin = false;

  private readonly elementRef = inject(ElementRef);

  iconData!: string;
  width!: string;
  transform!: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['icon']) {
      this.iconData = this.getIcon(changes['icon'].currentValue);
      this.transform = this.getTransform(this.icon);
    }

    if (changes['size']) {
      this.width = this.getSize(this.size);
    }
  }

  ngOnInit(): void {
    this.iconData = this.getIcon(this.icon);
    this.transform = this.getTransform(this.icon);
    this.width = this.getSize(this.size);
  }

  private getIcon(iconName: IconType): string {
    const iconData = icons[iconName];

    if (!iconData) {
      console.warn(
        `Could not find icon for ${iconName}, displaying default. Right click this and select reveal element to find out where:`,
        this.elementRef.nativeElement
      );
      return icons['exclamation-triangle'];
    }

    return iconData;
  }

  private getSize(size: IconSize): string {
    switch (size) {
      case 'ti':
        return '0.75rem';
      case 'sm':
        return '1rem';
      case 'rg':
        return '1.25rem';
      case 'md':
        return '1.5rem';
      case 'lg':
        return '2rem';
      case 'xxl':
        return '3rem';
      default:
        return '1.25rem';
    }
  }

  private getTransform(icon: string): string {
    switch (icon) {
      case 'kofo':
        return 'translate(-4312 6441.6) scale(1.6)';
      case 'ivr':
        return 'translate(-4312 7017.6) scale(1.6)';
      default:
        return '';
    }
  }
}
