import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';

import { Observable } from 'rxjs';

import {
  VorgangDetailsCountDTO,
  VorgangDetailsDTO,
  CommonListRequestDTO,
  CommonListResponseDTO,
  LokationDTO,
  AnfragedokumentDTO,
  AntwortdokumentDTO,
  ToebDTO,
  WeitereToebDTO
} from '../models';


export const BACKEND_SERVICE_BASE_API_URL = new InjectionToken<`https://${string}/`>('BACKEND_SERVICE_BASE_API_URL');

@Injectable()
export class BackendService {
  constructor(
    @Inject(BACKEND_SERVICE_BASE_API_URL) private readonly _baseApiUrl: `https://${string}/`,
    private http: HttpClient) {
  }

  public resendTeilvorgangEmail(id: string, email: string): Observable<void> {
    return this.http
      .post<void>(`${this._baseApiUrl}api-admin/v1/vorgang/teilvorgang/${id}/resend-email`, { email });
  }

  public resendTeilvorgang(id: string): Observable<void> {
    return this.http
      .post<void>(`${this._baseApiUrl}api-admin/v1/vorgang/teilvorgang/${id}/resend`, { });
  }

  public getVorgangCounts(id: string): Observable<VorgangDetailsCountDTO> {
    return this.http
      .get<VorgangDetailsCountDTO>(`${this._baseApiUrl}api-admin/v1/vorgang/details/counts/${id}`);
  }

  public getVorgangDetails(id: string): Observable<VorgangDetailsDTO> {
    return this.http
      .get<VorgangDetailsDTO>(`${this._baseApiUrl}api-admin/v1/vorgang/details/${id}`);
  }

  public getLokationen(request: CommonListRequestDTO): Observable<CommonListResponseDTO<Partial<LokationDTO>>> {
    return this.http
      .post<CommonListResponseDTO<Partial<LokationDTO>>>(`${this._baseApiUrl}api-admin/v1/vorgang/details/lokationen/list`, request);
  }

  public getAnfragedokumente(request: CommonListRequestDTO): Observable<CommonListResponseDTO<AnfragedokumentDTO>> {
    return this.http
      .post<CommonListResponseDTO<AnfragedokumentDTO>>(`${this._baseApiUrl}api-admin/v1/vorgang/details/anfragedokumente/list`, request);
  }

  public getAntwortdokumente(request: CommonListRequestDTO): Observable<CommonListResponseDTO<Partial<AntwortdokumentDTO>>> {
    return this.http
      .post<CommonListResponseDTO<AntwortdokumentDTO>>(`${this._baseApiUrl}api-admin/v1/vorgang/details/antwortdokumente/list`, request);
  }

  public getToebs(request: CommonListRequestDTO): Observable<CommonListResponseDTO<Partial<ToebDTO>>> {
    return this.http
      .post<CommonListResponseDTO<Partial<ToebDTO>>>(`${this._baseApiUrl}api-admin/v1/vorgang/details/toeb/list`, request);
  }

  public getWeitereToebs(request: CommonListRequestDTO): Observable<CommonListResponseDTO<Partial<WeitereToebDTO>>> {
    return this.http
      .post<CommonListResponseDTO<Partial<WeitereToebDTO>>>(`${this._baseApiUrl}api-admin/v1/vorgang/details/weitere-toeb/list`, request);
  }
}
