import { NOT_FOUND_INDEX } from "./constants";

export function extractFilenameFromContentDispositionHeader(
  contentDispositionHeader: string | null,
  fallback: string): string {

  if (!contentDispositionHeader) {
    return fallback;
  };

  const filenameToken = 'filename=';
  const filenameTokenIndex = contentDispositionHeader.indexOf(filenameToken);
  if (filenameTokenIndex === NOT_FOUND_INDEX) {
    return fallback;
  };

  const filenameStart = filenameTokenIndex + filenameToken.length;
  let filenameEnd = contentDispositionHeader.indexOf(';', filenameStart);
  if (filenameEnd === NOT_FOUND_INDEX) {
    filenameEnd = contentDispositionHeader.length;
  }

  const filename = contentDispositionHeader.substring(filenameStart, filenameEnd).trim();
  if (filename.startsWith('"') && filename.endsWith('"')) {
    return filename.substring(1, filename.length - 1);
  }

  return filename;
}
