import { Injectable } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { TableColumn } from '../models/table-column.model';
import { getDateForFilename } from '@core';

@Injectable({
  providedIn: 'root'
})
export class TableService {
  public tableName: string;

  private downloadBlob(blob: Blob | any, filename: string) {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  public exportToCsv<T>(columns: TableColumn<T>[], rows: object[], selectionModel: SelectionModel<any>, filename: string = '') {
    filename = filename === '' ? `${this.tableName}${getDateForFilename()}.csv` : filename;
    if (!rows || !rows.length) {
      return;
    }
    const fields = columns.filter(c => c.exportable !== false && c.display !== 'hidden');
    const separator = ',';
    const CR_LF = '\n'; //'\u0D0A';
    const keys = fields.map(f => f.key);
    const headers = fields.map(f => f.title);
    const csvContent =
      headers.join(separator) +
      CR_LF +
      rows
        .map(row => {
          return fields
            .map(f => {
              let cell = f.toExport(row, 'csv') || '';
              cell = cell instanceof Date ? cell.toLocaleString() : cell.toString().replace(/"/g, '""');
              if (cell.search(/("|,|\n)/g) >= 0) {
                cell = `"${cell}"`;
              }
              return cell;
            })
            .join(separator);
        })
        .join(CR_LF);

    const blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]) /* UTF-8 BOM */, csvContent], { type: 'text/csv;charset=utf-8' });
    this.downloadBlob(blob, filename);
  }

  public exportToJson(rows: object[], filename: string = '') {
    filename = filename === '' ? `${this.tableName}${getDateForFilename()}.json` : filename;
    const blob = new Blob([JSON.stringify(rows)], {
      type: 'text/csv;charset=utf-8;'
    });
    this.downloadBlob(blob, filename);
  }

  public loadSavedColumnInfo(columnInfo: TableColumn<any>[], saveName?: string): TableColumn<any>[] {
    // Only load if a save name is passed in
    if (saveName) {
      if (!localStorage) {
        return [];
      }

      const loadedInfo = localStorage.getItem(`${saveName}-columns`);

      if (loadedInfo) {
        return JSON.parse(loadedInfo);
      }
      this.saveColumnInfo(columnInfo);
      return columnInfo;
    }
    return [];
  }

  public saveColumnInfo(columnInfo: TableColumn<any>[], saveName: string = this.tableName): void {
    if (saveName) {
      if (!localStorage) {
        return;
      }

      localStorage.setItem(`${saveName}-columns`, JSON.stringify(columnInfo));
    }
  }
}
