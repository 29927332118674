import { Component, OnDestroy, OnInit } from '@angular/core';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { catchError } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { SubSink } from 'subsink';
import { SnackbarService } from '@utils';

@Component({
  selector: 'inf-formly-multiselect-type',
  template: `
    <mat-form-field [ngStyle]="{ width: props['width'] + '%' }" appearance="fill">
      <mat-label>{{ props.label }}{{ props['required'] ? ' *' : '' }}</mat-label>
      <input type="text" matInput placeholder="" readonly [formControl]="formControl" [formlyAttributes]="field" hidden />
      <span>{{ labels }}</span>
      <mat-icon matSuffix (click)="openLookupDialog()">search</mat-icon>
    </mat-form-field>
  `,
  styles: [
    `
      :host mat-form-field {
        mat-icon {
          cursor: pointer;
        }

        color: rgba(0, 0, 0, 0.87);
      }
    `
  ]
})
export class DialogSelectTypeComponent extends FieldType<FieldTypeConfig> implements OnInit, OnDestroy {
  private _subs = new SubSink();

  JSON = JSON;
  labels: string;

  constructor(
    private readonly _dialog: MatDialog,
    private _snackbar: SnackbarService
  ) {
    super();
  }

  ngOnInit() {
    if (!this.props['dialog']) {
      throw new Error('dialog not provided');
    }

    if (this.formControl.value && Array.isArray(this.formControl.value)) {
      console.log('initial form value', this.formControl.value);
      this.labels = (this.formControl.value as any[])?.map(v => v?.label).join(', ');
    }

    this._subs.sink = this.formControl.valueChanges.subscribe(values => {
      this.labels = values?.map(v => v?.label).join(', ');
    });
  }

  openLookupDialog() {
    const dialogRef = this._dialog.open(this.props?.['dialog'], {
      width: '800px',
      data: {
        ...this.props?.['dialogData'],
        model: (this.model[this.key as string] as any[]) ?? []
      }
    });

    this._subs.sink = dialogRef
      .afterClosed()
      .pipe(
        filter(result => result?.action === 'save'),
        distinctUntilChanged(),
        catchError((ex, caught) => {
          const errorMessage = ex?.error?.message ? ex?.error?.message : 'Bitte versuchen Sie es erneut';
          this._snackbar.open(errorMessage, false);
          return caught;
        })
      )
      .subscribe(res => {
        const selectedValues = res?.model?.selectedValues ?? [];
        this.setDefaultValues(selectedValues);
      });
  }

  setDefaultValues(values: OptionItem[]) {
    if (this.to['multiple']) {
      this.formControl.setValue(values);
    } else {
      if (values) {
        this.formControl.setValue(values[0]);
      }
    }
  }

  override ngOnDestroy() {
    this._subs.unsubscribe();
    super.ngOnDestroy();
  }
}

export interface OptionItem {
  label: string;
  value: string;
}
