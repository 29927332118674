import { AfterViewInit, Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { FieldType } from '@ngx-formly/material';
// import { FieldTypeConfig, FormlyConfig,  ɵwrapProperty as wrapProperty } from '@ngx-formly/core';
import { FieldTypeConfig, FormlyConfig } from '@ngx-formly/core';
import { MatInput } from '@angular/material/input';
import { MatDatepickerInput } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDateFormats } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

const DE_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'DD.MM.YYYY'
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@Component({
  selector: 'formly-field-mat-datepicker',
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
    { provide: DateAdapter, useClass: MomentDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: DE_DATE_FORMATS }
  ],
  template: `
    <mat-form-field>
      <mat-label>{{props?.label || ''}}</mat-label>
      <input
        matInput
        [id]="id"
        [errorStateMatcher]="errorStateMatcher"
        [formControl]="formControl"
        [matDatepicker]="picker"
        [matDatepickerFilter]="props['datepickerOptions'].filter"
        [max]="props['datepickerOptions'].max"
        [min]="props['datepickerOptions'].min"
        [placeholder]="props.placeholder"
        [tabindex]="props.tabindex"
        [readonly]="props.readonly"
        [required]="props.required"
        (focus)="picker.open()"
        (click)="picker.open()"
        (dateInput)="props['datepickerOptions'].dateInput(field, $event)"
        (dateChange)="props['datepickerOptions'].dateChange(field, $event)" />
      <mat-datepicker-toggle [disabled]="props.disabled" [for]="picker" matIconSuffix></mat-datepicker-toggle>
      <mat-datepicker
        #picker
        [color]="props['color']"
        [dateClass]="props['datepickerOptions'].dateClass"
        [disabled]="props['datepickerOptions'].disabled"
        [opened]="props['datepickerOptions'].opened"
        [panelClass]="props['datepickerOptions'].panelClass"
        [startAt]="props['datepickerOptions'].startAt"
        [startView]="props['datepickerOptions'].startView"
        [touchUi]="props['datepickerOptions'].touchUi"
        (monthSelected)="props['datepickerOptions'].monthSelected(field, $event, picker)"
        (yearSelected)="props['datepickerOptions'].yearSelected(field, $event, picker)"></mat-datepicker>
     
        <mat-error>
        <formly-validation-message [field]="field"></formly-validation-message>
      </mat-error>
      </mat-form-field>
  `
})
export class FormlyDatePickerComponent extends FieldType<FieldTypeConfig> {
  override defaultOptions = {
    templateOptions: {
      datepickerOptions: {
        startView: 'month',
        datepickerTogglePosition: 'suffix',
        dateInput: () => {},
        dateChange: () => {},
        monthSelected: () => {},
        yearSelected: () => {}
      }
    }
  };

  constructor(private config: FormlyConfig) {
    super();
  }
}
