import {Compiler, COMPILER_OPTIONS, CompilerFactory, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TableComponent} from './components/table.component';
import {MatTableModule} from '@angular/material/table';
import {CdkTableModule} from '@angular/cdk/table';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatMenuModule} from '@angular/material/menu';
import {MatDialogModule} from '@angular/material/dialog';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatButtonModule} from '@angular/material/button';
import {MatSelectModule} from '@angular/material/select';
import {FormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import {TableIntl} from './core/table-intl';
import {JitCompilerFactory} from '@angular/platform-browser-dynamic';
import {TableItemSizeDirective} from './core/table-item-size.directive';
import {TableCoreDirective} from './core/table-core.directive';
import {DynamicCellDirective} from './core/dynamic-cell.directive';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatDividerModule} from '@angular/material/divider';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {TableMenuModule} from './extensions/table-menu/table-menu.module';
import {TooltipModule} from './extensions/tooltip/tooltip.module';
import {ColumnFilterModule} from './extensions/filter/column-filter.module';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RowMenuModule} from './extensions/row-menu/row-menu.module';
import {SaveViewModule} from './extensions/save-view/save-view.module';
import {DynamicPipe} from './core/dynamic-pipe.pipe';
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import { ColumnSettingsModule } from '@components/table/extensions/column-settings/column-settings.module';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

// import { ComparableSelectionModel } from './core/comparable-selection-model';

export function createCompiler(compilerFactory: CompilerFactory): Compiler {
  return compilerFactory.createCompiler();
}

export function paginatorLabels(tableIntl: TableIntl) {
  const paginatorIntl = new MatPaginatorIntl();
  paginatorIntl.firstPageLabel = tableIntl?.paginatorLabels?.firstPageLabel;
  paginatorIntl.getRangeLabel = tableIntl?.paginatorLabels?.getRangeLabel;
  paginatorIntl.itemsPerPageLabel = tableIntl?.paginatorLabels?.itemsPerPageLabel;
  paginatorIntl.lastPageLabel = tableIntl?.paginatorLabels?.lastPageLabel;
  paginatorIntl.nextPageLabel = tableIntl?.paginatorLabels?.nextPageLabel;
  paginatorIntl.previousPageLabel = tableIntl?.paginatorLabels?.previousPageLabel;
  return paginatorIntl || null;
}

@NgModule({
  declarations: [DynamicPipe, DynamicCellDirective, TableComponent, TableCoreDirective, TableItemSizeDirective],
  imports: [
    CdkTableModule,
    MatSlideToggleModule,
    ColumnFilterModule,
    CommonModule,
    DragDropModule,
    FormsModule,
    MatButtonToggleModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    RowMenuModule,
    SaveViewModule,
    ScrollingModule,
    TableMenuModule,
    TooltipModule,
    ColumnSettingsModule
  ],
  exports: [TableComponent],
  providers: [
    {provide: COMPILER_OPTIONS, useValue: {}, multi: true},
    {
      provide: CompilerFactory,
      useClass: JitCompilerFactory,
      deps: [COMPILER_OPTIONS]
    },
    {provide: Compiler, useFactory: createCompiler, deps: [CompilerFactory]},
    TableIntl,
    {
      provide: MatPaginatorIntl,
      useFactory: paginatorLabels,
      deps: [TableIntl]
    }
  ]
})
export class TableModule {
}
