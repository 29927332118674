import { BrowserCacheLocation, Configuration, LogLevel } from '@azure/msal-browser';
import { environment } from '@environments/environment';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export const b2cPolicies = {
  names: {
    signUpSignIn: 'B2C_1_susi_v2',
    resetPassword: 'B2C_1_reset_v3',
    editProfile: 'B2C_1_edit_profile_v2'
  },
  authorities: {
    signUpSignIn: {
      authority: `https://${environment.oauth.b2cTenant}.b2clogin.com/${environment.oauth.b2cTenant}.onmicrosoft.com/${environment.oauth.flowSignInSignUp}`
    },
    resetPassword: {
      authority: `https://${environment.oauth.b2cTenant}.b2clogin.com/${environment.oauth.b2cTenant}.onmicrosoft.com/${environment.oauth.flowPasswordReset}`
    }
    // editProfile: {
    //   authority: `https://${environment.oauth.b2cTenant}.b2clogin.com/${environment.oauth.b2cTenant}.onmicrosoft.com/${environment.oauth.flowEditProfile}`,
    // },
  },
  authorityDomain: `${environment.oauth.b2cTenant}.b2clogin.com`
};

export const msalConfig: Configuration = {
  auth: {
    clientId: environment.oauth.clientId, // This is the ONLY mandatory field that you need to supply.
    authority: b2cPolicies.authorities.signUpSignIn.authority, // Defaults to "https://login.microsoftonline.com/common"
    knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
    // redirectUri: '/auth', // Points to window.location.origin by default. You must register this URI on Azure portal/App Registration.
    redirectUri: window.location.origin, // Points to window.location.origin by default. You must register this URI on Azure portal/App Registration.
    postLogoutRedirectUri: '/' // Points to window.location.origin by default.
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: isIE // Set this to "true" if you are having issues on IE11 or Edge. Remove this line to use Angular Universal
  },
  system: {
    loggerOptions: {
      loggerCallback(logLevel: LogLevel, message: string) {
        console.log(message);
      },
      logLevel: LogLevel.Error,
      piiLoggingEnabled: false
    }
  }
};

export const loginRequest = {
  scopes: [`openid`, `offline_access`, `https://${environment.oauth.b2cTenant}.onmicrosoft.com/${environment.oauth.clientId}/api`]
};

export const silentRequest = {
  scopes: [],
  loginHint: 'example@domain.net'
};
