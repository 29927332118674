import { AbstractFilter, FilterOperation } from './abstract-filter';
import { TableIntl } from '../../../core/table-intl';

export class NumberFilter extends AbstractFilter<number> {
  private static operationList: FilterOperation[] = [];

  constructor(public languagePack: TableIntl) {
    super();
    if (NumberFilter.operationList.length === 0) {
      NumberFilter.operationList = [
        {
          predicate: 'a == b',
          operator: '==',
          text: languagePack.filterLabels.NumberEquals
        },
        {
          predicate: 'a > b',
          operator: '>',
          text: languagePack.filterLabels.NumberGreaterThan
        },
        {
          predicate: 'a < b',
          operator: '<',
          text: languagePack.filterLabels.NumberLessThan
        }
      ];
    }
  }

  private _selectedIndex: number = null;
  get selectedIndex(): number {
    return this._selectedIndex;
  }

  set selectedIndex(value: number) {
    this._selectedIndex = value;
    if (value === 0 || value === 1 || value === 2) {
      this.parameters = [{ value: null, text: this.languagePack.filterLabels.Number }];
    } else {
      this.parameters = null;
    }
  }

  get selectedValue(): FilterOperation {
    if (this._selectedIndex !== null) {
      return NumberFilter.operationList[this._selectedIndex];
    } else {
      return null;
    }
  }

  public getOperations(): FilterOperation[] {
    return NumberFilter.operationList;
  }

  public toString(dynamicVariable: string): string {
    const a = '_a$';
    const b = '_b$';
    const predicate = this.selectedValue.predicate.replace('a', a).replace('b', b);
    const statement = predicate.replace(a, `${a}['${dynamicVariable}']`);

    if (this._selectedIndex === 0 || this._selectedIndex === 1 || this._selectedIndex === 2) {
      const value = this.parameters[0].value ? this.parameters[0].value.toString() : ' null ';
      return statement.replace(b, value);
    } else {
      return statement;
    }
  }
}
