import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormlyComponentsModule } from '@components/formly/formly-components.module';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { FeedbackService } from '@components/feedback/feedback.service';
import { MsalService } from '@azure/msal-angular';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { SubSink } from 'subsink';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'inf-feedback-modal',
  imports: [CommonModule, MatButtonModule, MatIconModule, MatProgressSpinnerModule, MatDialogModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule, FormlyMaterialModule, FormlyComponentsModule, FormlyMatDatepickerModule],
  standalone: true,
  template: `
    <div class="header">
      <h2 mat-dialog-title>Feedback</h2>
      <mat-icon (click)="onCancel()">clear</mat-icon>
    </div>
    <mat-dialog-content class="dialog-content">
      <form class="form" [formGroup]="feedbackForm">
        <mat-form-field>
          <mat-label>Problembeschreibung</mat-label>
          <textarea
            matInput
            rows="5"
            cdkTextareaAutosize
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="5"
            placeholder="Eine detaillierte Beschreibung des Problems. Was funktioniert nicht? Was ist die genaue Beobachtung"
            formControlName="problemDescription"></textarea>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Wie bin ich vorgegangen</mat-label>
          <textarea
            matInput
            rows="5"
            cdkTextareaAutosize
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="5"
            placeholder="Bitte beschreiben Sie die Schritte, die unternommen werden müssen, um den Fehler zu reproduzieren:"
            formControlName="stepsToReproduce"></textarea>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Erwartetes Ergebnis</mat-label>
          <textarea
            matInput
            rows="5"
            cdkTextareaAutosize
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="5"
            placeholder="Beschreiben Sie, was eigentlich passieren sollte."
            formControlName="expectedResult"></textarea>
        </mat-form-field>
      </form>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button class="button" mat-button type="button" (click)="onCancel()">Abbrechen</button>
      <button class="button" mat-raised-button color="primary" (click)="onSubmit()"
              [disabled]="feedbackForm.invalid || isSubmitting">
        Speichern
        <mat-progress-spinner *ngIf="isSubmitting" [diameter]="17" mode="indeterminate"
                              [color]="'primary'"></mat-progress-spinner>
      </button>
    </mat-dialog-actions>
  `,
  styles: [
    `
      .header {
        display: flex;
        justify-content: space-between;
        padding: 10px 10px 0 10px;

        h3 {
          margin-bottom: 1rem;
          font-weight: 500;
          font-size: 1rem;
        }

        mat-icon {
          margin-top: 1px;
          cursor: pointer;
        }
      }

      mat-dialog-actions {
        justify-content: flex-end;

        .button {
          margin-inline: 0.5rem;
          cursor: pointer;

          mat-progress-spinner {
            margin-inline-start: 0.5rem;
            display: inline-block;
          }
        }

        div {
          font-weight: 400;
          font-size: 1rem;
        }
      }

      mat-dialog-content {
        border-top: solid 1px #e5e5e5;
        border-bottom: solid 1px #e5e5e5;

        padding-top: 1rem;
        padding-bottom: 0.5rem;

        .form {
          display: grid;
          grid-template-columns: 1fr;
        }
      }
    `
  ]
})
export class FeedbackModalComponent implements OnDestroy {
  feedbackForm: FormGroup;
  isSubmitting = false;
  private subs = new SubSink();

  constructor(
    public dialogRef: MatDialogRef<FeedbackModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private feedbackService: FeedbackService,
    private authService: MsalService,
    private snackBar: MatSnackBar
  ) {
    this.feedbackForm = this.fb.group({
      problemDescription: [''],
      stepsToReproduce: [''],
      expectedResult: ['']
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    const activeAccount = this.authService.instance.getActiveAccount();
    const userName = activeAccount?.username;
    const feedback = this.feedbackForm.value;
    const url = window.location.href;

    this.isSubmitting = true;
    this.feedbackService.sendFeedback(url, userName, feedback).subscribe(
      (response) => {
        this.isSubmitting = false;
        this.dialogRef.close();
        this.snackBar.open('Feedback gesendet!', 'Schließen', {
          duration: 3000,
          panelClass: 'success'
        });
      },
      (error) => {
        console.error('Error submitting feedback:', error);
        this.isSubmitting = false;
      }
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}

