import { AbstractFilter, FilterOperation } from './abstract-filter';
import { TableIntl } from '../../../core/table-intl';

export class BooleanFilter extends AbstractFilter<string> {
  private static operationList: FilterOperation[] = [];

  constructor(public languagePack: TableIntl) {
    super();
    if (BooleanFilter.operationList.length === 0) {
      BooleanFilter.operationList = [{
        predicate: 'a == b',
        operator: '==',
        text: languagePack.filterLabels.BooleanEquals
      }];
    }
  }

  private _selectedIndex: number = null;
  get selectedIndex(): number {
    return this._selectedIndex;
  }

  set selectedIndex(value: number) {
    this._selectedIndex = value;
    if (value === 0) {
      this.parameters = [{ value: null, text: this.languagePack.filterLabels.Boolean }];
    } else {
      this.parameters = null;
    }
  }

  get selectedValue(): FilterOperation {
    if (this._selectedIndex !== null) {
      return BooleanFilter.operationList[this._selectedIndex];
    } else {
      return null;
    }
  }

  public getOperations(): FilterOperation[] {
    return BooleanFilter.operationList;
  }

  public toString(dynamicVariable: string): string {
    const a = '_a$';
    const b = '_b$';
    const modifiedPredicate = this.selectedValue.predicate === '==' ? 'a == b' : 'a == b';
    const predicate = modifiedPredicate.replace('a', a).replace('b', b);
    const statement = predicate.replace(a, `${a}['${dynamicVariable}']`);

    if (this._selectedIndex === 0) {
      const value = this.parameters[0].value ? this.parameters[0].value.toString() : ' null ';
      return statement.replace(b, value);
    } else {
      return statement;
    }
  }
}
