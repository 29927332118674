import { ApplicationConfig, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { provideRouter } from '@angular/router';

import { APP_ROUTES } from './app.routes';
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { HttpApikeyInterceptor } from '@core/http-apikey.interceptor';
import { QuillModule } from 'ngx-quill';
import { FormlyModule } from '@ngx-formly/core';
import * as configs from './configs';
import { loginRequest, msalConfig } from './configs';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalService
} from '@azure/msal-angular';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDateFormats } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { RoleGuard } from '@core/role.guard';
import { InteractionType, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { environment } from '@environments/environment';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { registerLocaleData } from '@angular/common';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: loginRequest
  };
}

function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  // protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', [
  //   'user.read',
  // ]);

  protectedResourceMap.set('/api/', [
    `openid`,
    `offline_access`,
    `profile`,
    `https://${environment.oauth.b2cTenant}.onmicrosoft.com/${environment.oauth.clientId}/api`
  ]);
  protectedResourceMap.set('/api-admin/', [
    `openid`,
    `offline_access`,
    `profile`,
    `https://${environment.oauth.b2cTenant}.onmicrosoft.com/${environment.oauth.clientId}/api`
  ]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

const DE_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'DD.MM.YYYY'
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    provideAnimations(),
    provideRouter(APP_ROUTES),
    { provide: HTTP_INTERCEPTORS, useClass: HttpApikeyInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DE_DATE_FORMATS },
    { provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory },
    { provide: MSAL_GUARD_CONFIG, useFactory: MSALGuardConfigFactory },
    { provide: MSAL_INTERCEPTOR_CONFIG, useFactory: MSALInterceptorConfigFactory },
    MsalService,
    MsalGuard,
    RoleGuard,
    MsalBroadcastService,
    importProvidersFrom(QuillModule.forRoot(), FormlyModule.forRoot(configs.formlyConfig))
  ]
};

// write code to check if we are in debug mode by checking if the host is localhost
// if so, set DEBUG to true
export const DEBUG = location.hostname === 'localhost';
