import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { Subject } from 'rxjs';
import { FilterLabels, MenuLabels, TableLabels, TableLanguage } from '../models/table-language.model';

@Injectable({
  providedIn: 'root'
})
export class TableIntl implements TableLanguage {
  menuLabels: MenuLabels = {
    saveData: 'Daten speichern',
    newSetting: 'Neue Einstellung',
    noSetting: 'Keine Einstellung ',
    fullScreen: 'Vollbild',

    columnSetting: 'Spalteneinstellung',
    saveTableSetting: 'View speichern',
    clearFilter: 'Filter löschen',
    jsonFile: 'JSON File',
    csvFile: 'CSV File',
    filterMode: 'Filter Mode:',
    filterLocalMode: 'Lokal',
    filterServerMode: 'Server',
    sortMode: 'Sort Mode:',
    sortLocalMode: 'Lokal',
    sortServerMode: 'Server',
    thereIsNoColumn: 'There is no column.'
  };

  paginatorLabels: MatPaginatorIntl = {
    changes: new Subject<void>(),
    itemsPerPageLabel: 'Zeilen pro Seite:',
    nextPageLabel: 'Nächste Seite:',
    previousPageLabel: 'Vorige Seite:',
    firstPageLabel: 'Erste Seite:',
    lastPageLabel: 'Letzte Seite:',
    getRangeLabel: (page: number, pageSize: number, length: number) => {
      if (length === 0 || pageSize === 0) {
        return `0 of ${length}`;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
      return `${startIndex + 1} - ${endIndex} von ${length}`;
    }
  };

  tableLabels: TableLabels = {
    NoData: 'Keine Daten vorhanden.'
  };

  filterLabels: FilterLabels = {
    Clear: 'Löschen',
    Search: 'Suchen',
    And: 'und',
    Or: 'oder',
    /* Text Compare */
    Text: 'Text',
    TextContains: 'enthält',
    TextEmpty: 'leer',
    TextStartsWith: 'beginnt mit',
    TextEndsWith: 'endet mit',
    TextEquals: 'gleich',
    TextNotEmpty: 'nicht leer',
    /* Number Compare */
    Number: 'Nummer',
    NumberEquals: 'gleich',
    NumberNotEquals: 'ungleich',
    NumberGreaterThan: 'größer als',
    NumberLessThan: 'kleiner als',
    NumberEmpty: 'leer',
    NumberNotEmpty: 'nicht leer',
    /* Category List Compare */
    CategoryContains: 'Contains',
    CategoryNotContains: 'Not Contains',
    /* Boolean Compare */
    Boolean: 'Boolean',
    BooleanEquals: 'gleich',
    Yes: 'Ja',
    No: 'Nein',
    All: 'Alle',
    /* Date Compare */
  };
}
