import { AbstractFilter, FilterOperation } from './abstract-filter';
import { TableIntl } from '../../../core/table-intl';

export class TextFilter extends AbstractFilter<string> {
  private static operationList: FilterOperation[] = [];

  constructor(public languagePack: TableIntl) {
    super();
    this._selectedIndex = 0;
    if (TextFilter.operationList.length === 0) {
      TextFilter.operationList = [
        {
          predicate: 'a.toString().includes(b)',
          operator: 'contains',
          text: languagePack.filterLabels.TextContains
        },
        {
          predicate: 'a.toString() === b.toString()',
          operator: '==',
          text: languagePack.filterLabels.TextEquals
        }];
    }
  }

  private _selectedIndex: number = null;
  get selectedIndex(): number {
    return this._selectedIndex;
  }

  set selectedIndex(value: number) {
    this._selectedIndex = value;
    if (value === 0 || value === 1) {
      this.parameters = [{ value: '', text: this.languagePack.filterLabels.Text }];
    } else {
      this.parameters = null;
    }
  }

  get selectedValue(): FilterOperation {
    if (this._selectedIndex !== null) {
      return TextFilter.operationList[this._selectedIndex];
    } else {
      return null;
    }
  }

  public getOperations(): FilterOperation[] {
    return TextFilter.operationList;
  }

  public toString(dynamicVariable: any): string {
    const a = '_a$';
    const b = '_b$';
    const predicate = this.selectedValue.predicate.replace('a', a).replace('b', b);
    const statement = predicate.replace(a, `${a}['${dynamicVariable}']?.toString()?.toLowerCase()`);

    if (this._selectedIndex === 0 || this._selectedIndex === 1) {
      const value = '\'' + (this.parameters[0].value !== null ? this.parameters[0].value.toLowerCase() : ' null ') + '\'';
      return statement.replace('_b$', value);
    } else {
      return statement;
    }
  }
}
