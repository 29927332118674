import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '@components/icon/icon.component';
import { SubNavComponent } from './sub-nav/sub-nav.component';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { NavigationService } from '@core/services';
import { MatDialog } from '@angular/material/dialog';
import { FeedbackModalComponent } from '@components/feedback/feedback.component';

@Component({
  selector: 'inf-nav',
  standalone: true,
  imports: [CommonModule, RouterLink, RouterLinkActive, IconComponent, SubNavComponent],
  templateUrl: './nav.component.html',
  styleUrl: './nav.component.scss'
})
export class NavComponent {
  dialog = inject(MatDialog);
  navService = inject(NavigationService);

  openFeedbackDialog(): void {
    this.dialog.open(FeedbackModalComponent, {
      width: '80vw',
      maxWidth: '500px'
    });
  }
}
