import { SelectionModel } from '@angular/cdk/collections';
import { Sort } from '@angular/material/sort';

import { HashMap } from '../core/types';
import { TableColumn } from './table-column.model';
import { ContextMenuItem } from './context-menu-model';

export type TableSelectionMode = 'none' | 'single' | 'multiple';

export type RowEventType = 'RowSelectionChange' | 'RowActionMenu' | 'RowClick' | 'CellClick' | 'BeforeContextMenuOpen' | 'ContextMenuClick';

export interface TableRow {
  id?: number;
  rowActionMenu?: { [key: string]: ContextMenuItem };
  options?: RowOption;
}

export interface RowEvent {
  event: RowEventType | any;
  sender: {
    row?: any;
    column?: TableColumn<any>;
    selectionModel?: SelectionModel<any>;
    [t: string]: any;
  };
}

export interface TableEvent {
  event:  'SortChanged';
  sender: Sort
}

export interface RowActionMenuEvent<T> {
  actionItem: ContextMenuItem;
  rowItem: T;
}

export interface RowOption extends HashMap<any> {
  style?: any;
  class?: any;
  selected?: any;
  disabled?: any;
}
