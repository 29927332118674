import { Routes } from '@angular/router';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { RoleGuard } from '@core/role.guard';
import { roles } from './configs';
import { UnauthorizedComponent } from '@pages/unauthorized/unauthorized.component';
import { OrganisationShellComponent } from '@pages/organisation/organisation-shell.component';
import { ToebShellComponent } from '@pages/toebs/toeb-shell.component';
import { AufgabenShellComponent } from '@pages/aufgaben/aufgaben-shell.component';
import { NutzerShellComponent } from '@pages/nutzer/nutzer-shell.component';
import { EinstellungenShellComponent } from '@pages/einstellungen/einstellungen-shell.component';
import { VorgaengeShellComponent } from '@pages/vorgaenge/vorgaenge-shell.component';
import { TransaktionenShellComponent } from '@pages/transaktionen/transaktionen-shell.component';
import { DashboardShellComponent } from '@pages/dashboard/dashboard-shell.component';
import { NavigationService } from '@core/services';
import { Role } from '@core/types';
import { NewsShellComponent } from '@pages/news/news-shell.component';

export const APP_ROUTES: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  {
    path: 'dashboard',
    component: DashboardShellComponent,
    canActivate: [MsalGuard],
    loadChildren: () => import('./pages/dashboard/dashboard.routes')
  },
  {
    path: 'organisation',
    component: OrganisationShellComponent,
    canActivate: [MsalGuard],
    loadChildren: () => import('./pages/organisation/organisation.routes')
  },
  {
    path: 'toebs',
    component: ToebShellComponent,
    canActivate: [MsalGuard],
    children: [
      {
        path: 'alle',
        loadComponent: () => import('./pages/toebs/components/toeb/toeb.component').then(mod => mod.ToebComponent),
        canActivate: [RoleGuard],
        data: { expectedRoles: [Role.InfrestAdmin, Role.SuperAdmin, Role.ToeBPflege] }
      },
      {
        path: 'entwurf',
        loadComponent: () => import('./pages/toebs/components/toeb-draft/toeb-draft.component').then(mod => mod.ToebDraftComponent),
        canActivate: [RoleGuard],
        data: { expectedRoles: [Role.InfrestAdmin, Role.SuperAdmin, Role.ToeBPflege] }
      },
      {
        path: 'freigabe',
        loadComponent: () => import('./pages/toebs/components/toeb-freigabe/toeb-freigabe.component').then(mod => mod.ToebFreigabeComponent),
        canActivate: [RoleGuard],
        data: { expectedRoles: [Role.InfrestAdmin, Role.SuperAdmin, Role.ToeBFreigabe] }
      }
    ]
  },
  {
    path: 'transaktionen',
    component: TransaktionenShellComponent,
    canActivate: [MsalGuard],
    loadChildren: () => import('./pages/transaktionen/transaktionen.routes')
  },
  {
    path: 'nutzer',
    component: NutzerShellComponent,
    canActivate: [MsalGuard],
    loadChildren: () => import('./pages/nutzer/nutzer.routes')
  },
  {
    path: 'news',
    component: NewsShellComponent,
    canActivate: [MsalGuard],
    loadChildren: () => import('./pages/news/news.routes')
  },
  {
    path: 'einstellungen',
    component: EinstellungenShellComponent,
    canActivate: [MsalGuard],
    loadChildren: () => import('./pages/einstellungen/einstellungen.routes')
  },
  {
    path: 'aufgaben',
    component: AufgabenShellComponent,
    canActivate: [MsalGuard],
    loadChildren: () => import('./pages/aufgaben/aufgaben.routes') },
  {
    path: 'vorgaenge',
    component: VorgaengeShellComponent,
    canActivate: [MsalGuard],
    children: [
      {
        path: 'alle',
        loadComponent: () => import('./pages/vorgaenge/components/vorgaenge/vorgaenge.component').then(mod => mod.VorgaengeComponent),
        canActivate: [RoleGuard],
        data: {
          expectedRoles: [Role.InfrestAdmin, Role.SuperAdmin, Role.Kundenservice],
          title: 'Alle Teilvorgänge',
          filters: []
        }
      },
      {
        path: 'dl-vorgaenge',
        loadComponent: () => import('./pages/vorgaenge/components/dl-vorgaenge/dl-vorgaenge.component').then(mod => mod.DlVorgaengeComponent),
        canActivate: [RoleGuard],
        data: {
          expectedRoles: [Role.InfrestAdmin, Role.SuperAdmin, Role.Diensleistung],
          title: 'Alle DL-Vorgänge',
          filters: []
        }
      },
      {
        path: 'dl-vorgaenge-nicht-zugewiesene',
        loadComponent: () => import('./pages/vorgaenge/components/dl-vorgaenge/dl-vorgaenge.component').then(mod => mod.DlVorgaengeComponent),
        canActivate: [RoleGuard],
        data: {
          expectedRoles: [Role.InfrestAdmin, Role.SuperAdmin, Role.Diensleistung],
          title: 'Nicht zugewiesene DL-Vorgänge',
          filters: [{ column: 'dlOrganisation', operator: 'null', term: '', not: false }]
        }
      },
      {
        path: 'bsa-uebertragung',
        loadComponent: () => import('./pages/vorgaenge/components/bsa-uebertragung/bsa-uebertragung.component').then(mod => mod.BsaUebertragungComponent),
        canActivate: [RoleGuard],
        data: {
          expectedRoles: [Role.InfrestAdmin, Role.SuperAdmin],
          filters: [{ column: 'bsa_status', operator: 'null', term: '', not: true }],
          title: 'BSA Übertragung'
        }
      },
      {
        path: 'oplex-uebertragung',
        loadComponent: () => import('./pages/vorgaenge/components/oplex-uebertragung/oplex-uebertragung.component').then(mod => mod.OplexUebertragungComponent),
        canActivate: [RoleGuard],
        data: {
          expectedRoles: [Role.InfrestAdmin, Role.SuperAdmin],
          filters: [],
          title: 'OPLEX Übertragung'
        }
      }
    ],
    providers: [NavigationService]
  },
  {
    path: 'auftraggeber-beziehung',
    loadComponent: () =>
      import('@pages/organisation/components/auftraggeber-beziehung/auftraggeber-beziehung.component').then(mod => mod.AuftraggeberBeziehung),
    canActivate: [RoleGuard],
    data: { expectedRoles: [roles.InfrestAdmin, roles.SuperAdmin, roles.Kundenservice] }
  },
  {
    // Needed for handling redirect after login
    path: 'auth',
    component: MsalRedirectComponent
  },
  {
    path: 'unauthorized',
    canActivate: [MsalGuard],
    component: UnauthorizedComponent
  }
];
