import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormlyWrapperComponent } from './formly-wrapper.component';
import { AutocompleteTypeComponent } from './autocomplete-type.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyModule } from '@ngx-formly/core';
import { SelectTypeComponent } from './select-type.component';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { FileUploadTypeComponent } from './file-upload-type/file-upload-type.component';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FileDragAndDropDirective } from './file-upload-type/directives/file-drag-and-drop.directive';
import { CustomValidatorDirective } from './file-upload-type/directives/custom-validator.directive';
import { MatButtonModule } from '@angular/material/button';
import { FormatFileSizePipe } from './file-upload-type/services/format-file-size.pipe';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FileValueAccessor } from '@utils/file-value-accessor';
import { DialogSelectTypeComponent } from '@components/formly/dialog-select-type.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ChipInputTypeComponent } from '@components/formly/chip-input-type.component';
import { MatChipsModule } from '@angular/material/chips';
import { QuillModule } from 'ngx-quill';
import { RteTypeComponent } from './rte-type.component';
import { FormlyExpandableWrapper } from '@components/formly/exandable-wrapper.component';
import { MatExpansionPanel, MatExpansionPanelDescription, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';
import { FormlyDatePickerComponent } from '@components/formly/datepicker-type.component';
import { MatDatepicker, MatDatepickerInput, MatDatepickerToggle } from '@angular/material/datepicker';

@NgModule({
  declarations: [
    FormlyExpandableWrapper,
    FormlyWrapperComponent,
    FileValueAccessor,
    AutocompleteTypeComponent,
    SelectTypeComponent,
    FileUploadTypeComponent,
    FileDragAndDropDirective,
    CustomValidatorDirective,
    FormatFileSizePipe,
    DialogSelectTypeComponent,
    ChipInputTypeComponent,
    RteTypeComponent,
    FormlyDatePickerComponent
  ],
  imports: [
    CommonModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    FormlyMaterialModule,
    MatChipsModule,
    MatInputModule,
    MatIconModule,
    MatSnackBarModule,
    MatButtonModule,
    MatProgressBarModule,
    MatDialogModule,
    MatSelectModule,
    FormlyModule.forChild({}),
    QuillModule,
    MatExpansionPanel,
    MatExpansionPanelTitle,
    MatExpansionPanelDescription,
    MatExpansionPanelHeader,
    MatDatepicker,
    MatDatepickerToggle,
    MatDatepickerInput
  ],
  exports: [FormlyWrapperComponent, FormlyDatePickerComponent, FormlyExpandableWrapper, AutocompleteTypeComponent, SelectTypeComponent, FileUploadTypeComponent]
})
export class FormlyComponentsModule {}
