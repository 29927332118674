import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-section',
  template: `
    <div>
      <hr />
      <h3 class="mat-subheading-2">{{ props.label }}</h3>
    </div>
  `
})
export class FormlySectionComponent extends FieldType {
}