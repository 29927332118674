import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  EventEmitter, HostBinding,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatMenuTrigger} from '@angular/material/menu';
import {Overlay, OverlayContainer, OverlayPositionBuilder, OverlayRef} from '@angular/cdk/overlay';
import {PageEvent} from '@angular/material/paginator';
import {ComponentPortal} from '@angular/cdk/portal';
import {Subscription} from "rxjs";

import {TableCoreDirective} from '../core/table-core.directive';
import {TableRow, TableSelectionMode} from '../models/table-row.model';
import {TableIntl} from '../core/table-intl';
import {ContextMenuItem} from '../models/context-menu-model';
import {TableColumn} from '../models/table-column.model';
import {HashMap, isNullOrUndefined} from '../core/types';
import {SettingItem, TableSetting} from '../models/table-settings.model';
import {deepClone, requestFullscreen} from '../utils';
import {TableService} from '../services/table.service';
import {FixedSizeTableVirtualScrollStrategy} from '../core/fixed-size-table-virtual-scroll-strategy';
import {AbstractFilter} from '../extensions/filter/compare/abstract-filter';
import {TooltipComponent} from '../extensions/tooltip/tooltip.component';
import {IPipe} from '../models/pipe.model';
import { FilterChangeEvent } from '../models';
import { TableMenuActionChange } from '../extensions/table-menu/table-menu.component';

@Component({
  selector: 'inf-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  providers: [
    {provide: 'DYNAMIC_CELL', useExisting: TableComponent},
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableComponent<T extends TableRow> extends TableCoreDirective<T> implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('tbl', {static: true}) tbl: any;
  @ViewChild(MatMenuTrigger) contextMenu!: MatMenuTrigger;
  @ViewChild('tooltip') tooltipRef!: TemplateRef<any>;

  @HostBinding('style.height.px')
  height: string = null;

  @Input()
  get setting() {
    return this.tableSetting;
  }

  set setting(value: TableSetting) {
    if (!isNullOrUndefined(value)) {
      value.alternativeRowStyle = value.alternativeRowStyle || this.tableSetting.alternativeRowStyle;
      value.columnSetting = value.columnSetting || this.tableSetting.columnSetting;
      value.normalRowStyle = value.normalRowStyle || this.tableSetting.normalRowStyle;
      value.visibleActionMenu = value.visibleActionMenu || this.tableSetting.visibleActionMenu;
      value.visibleColumnSettingsMenu = value.visibleColumnSettingsMenu || this.tableSetting.visibleColumnSettingsMenu;
      value.visibleTableMenu = value.visibleTableMenu ?? this.tableSetting.visibleTableMenu;
      value.visibleHeaderMenu = value.visibleHeaderMenu ?? this.tableSetting.visibleHeaderMenu;
      value.autoHeight = value.autoHeight || this.tableSetting.autoHeight;
      value.saveSettingsMode = value.saveSettingsMode || this.tableSetting.saveSettingsMode;
      this.pagination.pageSize = value.pageSize || this.tableSetting.pageSize || this.pagination.pageSize;

      value?.columnSetting?.forEach(column => {
        const originalColumn = this.columns?.find(c => c.key === column.key);
        if (originalColumn) {
          column = {...originalColumn, ...column};
        }
        this.tableSetting = value;
        this.setDisplayedColumns();
      });
    }
  }

  @Input() selectionType?: TableSelectionMode = 'none';

  private _viewportSizeChanged: any = null;

  @Input()
  get viewportSizeChanged() {
    return this._viewportSizeChanged;
  }
  set viewportSizeChanged(value) {
    const scrollStrategy: FixedSizeTableVirtualScrollStrategy = this.viewport['_scrollStrategy'];
    scrollStrategy?.viewport?.checkViewportSize();
  }

  @Output() onRowsSelected = new EventEmitter<any>();
  @Output() onFilterChange = new EventEmitter<FilterChangeEvent>();
  @Output() onSelectionChange = new EventEmitter<any>();
  @Output() onDownloadEvent: EventEmitter<string> = new EventEmitter<string>();

  init: boolean = false;
  contextMenuPosition = {x: '0px', y: '0px'};
  overlayRef: OverlayRef = null;

  private _dsSubscription: Subscription = new Subscription();

  constructor(
    public dialog: MatDialog,
    public renderer: Renderer2,
    public overlay: Overlay,
    public tableLanguage: TableIntl,
    tableService: TableService,
    private _overlayContainer: OverlayContainer,
    private _overlayPositionBuilder: OverlayPositionBuilder,
    cd: ChangeDetectorRef
  ) {
    super(tableService, cd);

    this._overlayContainer.getContainerElement().addEventListener('contextmenu', e => {
      e.preventDefault();
      return false;
    });
  }

  ngOnInit() {
    setTimeout(() => {
      this.init = true;
      this.cd.detectChanges();
    }, 1000);
  }

  ngAfterViewInit() {
    this.tvsDS.paginator = this.paginator;
    this.tvsDS.sort = this.sort;
    this._dsSubscription = this.dataSource.subscribe(({items, loading, totalCount, filters, sort}: any) => {
      items = deepClone(items) || [];
      this.rowSelectionModel.clear();
      this.tvsDS.data = [];
      this.initSystemField(items);
      this.tvsDS.data = items;
      this.refreshUI();
    });

    this.tvsDS?.sort?.sortChange?.subscribe(sort => {
      this.pagination.pageIndex = 0;
      this.onTableEvent.emit({event: 'SortChanged', sender: sort});
    });
  }

  protected initSystemField(data: any[]) {
    if (data) {
      data = data.map((item, index: number) => {
        item._id = index;
        item.option = item.option || {};
        return item;
      });
    }
  }

  autoHeight() {
    const minHeight = this.headerHeight + (this.rowHeight + 1) * this.dataSource.value.items?.length;
    return minHeight.toString();
  }

  tableMenuActionChange(e: TableMenuActionChange) {
    if (e.type === 'TableSetting') {
      this.settingChange.emit({type: 'apply', setting: this.tableSetting});
      this.refreshColumn(this.tableSetting.columnSetting);
    } else if (e.type === 'DefaultSetting') {
      (this.setting.settingsList || []).forEach(setting => {
        if (setting.settingsName === e.data) {
          setting.isDefaultSetting = true;
        } else {
          setting.isDefaultSetting = false;
        }
      });
      this.settingChange.emit({type: 'default', setting: this.tableSetting});
    } else if (e.type === 'SaveSetting') {
      const newSetting = Object.assign({}, this.setting);
      delete newSetting.settingsList;
      newSetting.settingsName = e.data;
      const settingIndex = (this.setting.settingsList || []).findIndex(f => f.settingsName === e.data);
      if (settingIndex === -1) {
        this.setting?.settingsList?.push(JSON.parse(JSON.stringify(newSetting)));
        this.settingChange.emit({type: 'create', setting: this.tableSetting});
      } else {
        if (this.setting && this.setting.settingsList) {
          this.setting.settingsList[settingIndex] = JSON.parse(JSON.stringify(newSetting));
          this.settingChange.emit({type: 'save', setting: this.tableSetting});
        }
      }
    } else if (e.type === 'DeleteSetting') {
      this.setting.settingsList = this.setting.settingsList.filter(s => s.settingsName !== e.data.settingName);
      this.setting.columnSetting.filter(f => f.display === 'hidden').forEach(f => (f.display = 'visible'));
      this.refreshColumn(this.setting.columnSetting);
      this.settingChange.emit({type: 'delete', setting: this.tableSetting});
    } else if (e.type === 'SelectSetting') {
      let setting: SettingItem = null;
      this.setting.settingsList.forEach(s => {
        if (s.settingsName === e.data) {
          s.isCurrentSetting = true;
          setting = Object.assign(
            {},
            this.setting.settingsList.find(s => s.settingsName === e.data)
          );
        } else {
          s.isCurrentSetting = false;
        }
      });
      setting.settingsList = this.setting.settingsList;
      delete setting.isCurrentSetting;
      delete setting.isDefaultSetting;
      if (this.paginationMode !== 'none' && this.pagination.pageSize != setting?.pageSize) {
        this.pagination.pageSize = setting?.pageSize || this.pagination.pageSize;
        this.paginationChange.emit(this.pagination);
      }
      /* Dynamic Cell must update when setting change */
      setting.columnSetting?.forEach(column => {
        const originalColumn = this.columns.find(c => c.key === column.key);
        column = {...originalColumn, ...column};
      });
      this.tableSetting = setting;
      this.refreshColumn(this.setting.columnSetting);
      this.settingChange.emit({type: 'select', setting: this.tableSetting});
    } else if (e.type === 'FullScreenMode') {
      requestFullscreen(this.tbl.elementRef);
    } else if (e.type === 'Download') {
      if (this.setting.options.downloadMode === 'server-side') {
        this.onDownloadEvent.emit(e.data);
      } else {
        if (e.data === 'CSV') {
          this.tableService.exportToCsv<T>(
            this.columns,
            this.tvsDS.filteredData,
            this.rowSelectionModel,
          );
        } else if (e.data === 'JSON') {
          this.tableService.exportToJson(this.tvsDS.filteredData);
        }
      }
    } else if (e.type === 'FilterClear') {
      this.tvsDS.clearFilter();
    }
  }

  pagination_onChange(e: PageEvent) {
    if (this.paginationMode !== 'none') {
      this.pending = true;
      this.tvsDS.refreshFilterPredicate();
      this.pagination.length = e.length;
      this.pagination.pageIndex = e.pageIndex;
      this.pagination.pageSize = e.pageSize;
      this.setting.pageSize = e.pageSize; /* Save Page Size when need in setting config */
      this.paginationChange.emit(this.pagination);
    }
  }

  onRowSelection(e, row, column: TableColumn<T>) {
    if (this.rowSelectionMode && this.rowSelectionMode !== 'none' && column.rowSelectable !== false) {
      this.onRowSelectionChange(e, row);
    }
  }

  ngOnDestroy() {
    this._dsSubscription.unsubscribe();
  }

  indexTrackFn = (index: number) => index;

  trackColumn(index: number, item: TableColumn<T>): string {
    return `${item.index}`;
  }

  public refreshUI() {
    if (this.tableSetting.autoHeight === true) {
      this.height = this.autoHeight();
    } else {
      this.height = null;
    }
    this.refreshColumn(this.tableColumns);
    this.cd.detectChanges();
    this.tvsDS.columns = this.columns;
    const scrollStrategy: FixedSizeTableVirtualScrollStrategy = this.viewport['_scrollStrategy'];
    scrollStrategy?.viewport?.checkViewportSize();
    // scrollStrategy?.viewport?.scrollToOffset(0);
    this.cd.detectChanges();
  }

  rowStyle(row) {
    let style: any = row?.option?.style || {};
    if (this.setting.alternativeRowStyle && row.id % 2 === 0) {
      // style is high priority
      style = {...this.setting.alternativeRowStyle, ...style};
    }

    if (this.setting.rowStyle) {
      style = {...this.setting.rowStyle, ...style};
    }

    return style;
  }

  filter_onChanged(column: TableColumn<T>, filter: AbstractFilter[]) {
    this.pending = true;
    this.tvsDS.setFilter(column.key, filter).subscribe(() => {
      this.clearSelection();
      this.pending = false;
    });
    this.onFilterChange.emit({
      filter,
      column: column.key
    });
  }

  onCellClick(e, row, column: TableColumn<T>) {
    if (column.cellTooltipEnabled === true) {
      this.closeTooltip();
    }

    this.onRowSelection(e, row, column);
    if (column.clickable !== false && (column.clickType === null || column.clickType === 'cell')) {
      this.onRowEvent.emit({
        event: 'CellClick',
        sender: {row: row, column: column}
      });
    }
  }

  onLabelClick(e, row, column: TableColumn<T>) {
    if (column.clickable !== false && column.clickType === 'label') {
      this.onRowEvent.emit({
        event: 'LabelClick',
        sender: {row: row, column: column, e: e}
      });
    }
  }

  onRowDblClick(e, row) {
    this.onRowEvent.emit({event: 'DoubleClick', sender: {row: row, e: e}});
  }

  onRowClick(e, row) {
    this.onRowEvent.emit({event: 'RowClick', sender: {row: row, e: e}});
  }

  onSelectActionMenuOption(event: any, item: T) {
    event.menuItem.onSelect(event, item);
  }

  currentContextMenuSender: any = {};

  onContextMenu(event: MouseEvent, column: TableColumn<T>, row: any) {
    if (this.currentContextMenuSender?.time && new Date().getTime() - this.currentContextMenuSender.time < 500) {
      return;
    }
    this.contextMenu.closeMenu();
    if (this.contextMenuItems?.length === 0) {
      return;
    }
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.currentContextMenuSender = {
      column: column,
      row: row,
      time: new Date().getTime()
    };
    this.contextMenu.menuData = this.currentContextMenuSender;
    this.contextMenu.menu.focusFirstItem('mouse');
    this.onRowEvent.emit({
      event: 'BeforeContextMenuOpen',
      sender: {row: row, column: column, contextMenu: this.contextMenuItems}
    });
    this.contextMenu.openMenu();
  }

  onContextMenuItemClick(data: ContextMenuItem) {
    this.contextMenu.menuData.item = data;
    this.onRowEvent.emit({
      event: 'ContextMenuClick',
      sender: this.contextMenu.menuData
    });
  }

  rowMenuActionChange(contextMenuItem: ContextMenuItem, row: any) {
    this.onRowEvent.emit({
      event: 'RowActionMenu',
      sender: {row: row, action: contextMenuItem}
    });
  }

  tooltip_onChanged(column: TableColumn<T>, row: any, elementRef: any, show: boolean) {
    if (column.cellTooltipEnabled === true) {
      if (show === true && row[column.key]) {
        if (this.overlayRef !== null) {
          this.closeTooltip();
        }

        const positionStrategy = this._overlayPositionBuilder.flexibleConnectedTo(elementRef).withPositions([
          {
            originX: 'center',
            originY: 'top',
            overlayX: 'center',
            overlayY: 'bottom',
            offsetY: -8
          }
        ]);

        this.overlayRef = this.overlay.create({positionStrategy});

        let usingValue = row[column.key];

        if (column.pipes && column.pipes.length > 0) {
          column.pipes.forEach((pipe: IPipe) => {
            const injector = Injector.create({
              name: 'DynamicPipe',
              // parent: this.injector,
              providers: [{provide: pipe.token}]
            });
            const _pipe = injector.get(pipe.token);
            usingValue = _pipe.transform(row[column.key], ...pipe.data);
          });
        }

        const option = {
          providers: [
            {
              provide: 'tooltipConfig',
              // useValue: row[column.key]
              useValue: usingValue
            }
          ]
        };

        const injector = Injector.create(option);
        const tooltipRef: ComponentRef<TooltipComponent> = this.overlayRef.attach(new ComponentPortal(TooltipComponent, null, injector));
        setTimeout(() => {
          tooltipRef.destroy();
        }, 5000);
      } else if (show === false && this.overlayRef !== null) {
        this.closeTooltip();
      }
    }
  }

  closeTooltip() {
    this.overlayRef?.detach();
    this.overlayRef = null;
  }

  ellipsis(column: TableColumn<T>, cell: boolean = true) {
    if (cell === true && column.cellEllipsisRow > 0) {
      return {
        display: '-webkit-box',
        '-webkit-line-clamp': column?.cellEllipsisRow,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        'white-space': 'pre-wrap'
      };
    } else if (cell === true && column.headerEllipsisRow > 0) {
      return {
        display: '-webkit-box',
        '-webkit-line-clamp': column?.headerEllipsisRow,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        'white-space': 'pre-wrap'
      };
    }
    return {};
  }

  public get inverseOfTranslation(): number {
    if (!this.viewport || !this.viewport['_renderedContentOffset']) {
      return -0;
    }
    let offset = this.viewport['_renderedContentOffset'];
    return -offset;
  }

  headerClass(column: TableColumn<T>) {
    return column?.classNames;
  }

  cellClass(option, column) {
    let className = null;
    if (option && column.key) {
      className = option[column.key] ? option[column.key].style : null;
    }

    if (className === null) {
      return column.cellClass;
    } else {
      return {...className, ...column.cellClass};
    }
  }

  cellStyle(option: HashMap<any>, column) {
    let style = null;
    if (option && column.key) {
      style = option[column.key] ? option[column.key].style : null;
    }
    /* consider to column width resize */
    if (style === null) {
      return {...column.cellStyle, ...column.style};
    } else {
      return {...style, ...column.cellStyle, ...column?.style};
    }
  }

  cellIcon(option, cellName) {
    if (option && cellName) {
      return option[cellName] ? option[cellName].icon : null;
    } else {
      return null;
    }
  }

  resolvePath(obj, path) {
    return path.split('.').reduce((prev, curr) => {
      return (prev ? prev[curr] : undefined)
    }, obj);
  }
}
