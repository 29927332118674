import { inject, Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import NavigationItems from '../constants/navigation-items.const';
import { NavigationItem, NavigationState } from '@core/types';
import { filter, map, startWith } from 'rxjs/operators';
import { signalSlice } from 'ngxtension/signal-slice';
import { Observable, of } from 'rxjs';
import { MsalService } from '@azure/msal-angular';

const ADMIN_CLAIM_NAME = 'extension_InfrestGroups';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  router = inject(Router);
  route = inject(ActivatedRoute);
  authService = inject(MsalService)

  private _initialState: NavigationState = {
    navigationItems: [],
    selectedNavigationItem: undefined,
    expanded: true
  };

  getRoles = () => {
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      activeAccount = this.authService.instance.getAllAccounts()[0];
    }

    return (activeAccount?.idTokenClaims?.[ADMIN_CLAIM_NAME] as string)?.split?.(',')?.map(role => role?.trim()) || []
  }

  // sources
  navItems$ = of(NavigationItems(this.getRoles())).pipe(
    map((items: NavigationItem[]) => {
      const url = this.route['_routerState']['snapshot'].url;
      const firstLevel = url.split('/')[1];
      const topLevelRoute = firstLevel ? `/${firstLevel}` : `/dashboard`;
      const item = items.find(item => item.routerLink === topLevelRoute);
      return { navigationItems: items, selectedNavigationItem: item };
    })
  );

  selectedNavItem$ = this.router.events.pipe(
    filter(e => e instanceof NavigationEnd),
    startWith(this.router),
    map(event => {
      if (event instanceof NavigationEnd) {
        const firstLevel = event.url.split('/')[1];
        const topLevelRoute = firstLevel ? `/${firstLevel}` : `/dashboard`;
        const item = this.state.navigationItems().find(item => item.routerLink === topLevelRoute);
        return { selectedNavigationItem: item };
      } else {
        return { selectedNavigationItem: null };
      }
    })
  );

  expanded$ = of(localStorage.getItem('nav_expanded')).pipe(
    map(expanded => {
      if (expanded === null) {
        localStorage.setItem('nav_expanded', 'true');
        return { expanded: true };
      }
      return { expanded: expanded === 'true' };
    }),
    startWith({ expanded: true })
  );

  // state
  state = signalSlice({
    initialState: this._initialState,
    sources: [this.navItems$, this.selectedNavItem$, this.expanded$],
    selectors: state => ({
      navSubItems: () => state().navigationItems.find(item => item.id === state().selectedNavigationItem?.id)?.groups
    }),
    actionSources: {
      toggleNav: (state, $: Observable<void>) => {
        return $.pipe(
          map(() => {
            const newExpandedState = !state().expanded;
            localStorage.setItem('nav_expanded', newExpandedState.toString());
            return { expanded: newExpandedState };
          })
        );
      },
      setSelectedNavigationItem: (state, item$: Observable<NavigationItem>) => {
        return item$.pipe(map(item => ({ selectedNavigationItem: item })));
      },
      loadNavigationItems: (state, $: Observable<void>) => {
        return $.pipe(
          map(() => {
            return {
              navigationItems: NavigationItems(this.getRoles())
            }
          })
        )
      }
    }
  });

  constructor() {}
}
