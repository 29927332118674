import * as rfdc from 'rfdc';

export interface DeepCloneOptions {
  circles?: boolean;
  proto?: boolean;
}

export const deepClone = (valToClone, options?: DeepCloneOptions) => {
  const defaultOptions = {
    circles: false,
    proto: true,
    ...(options ? options : {})
  };
  return rfdc(defaultOptions)(valToClone);
};

export const autoConvertMapToObject = map => {
  const obj = {};
  for (const item of [...map]) {
    const [key, value] = item;
    obj[key] = value;
  }
  return obj;
};

export const sortByLocalCompare = (
  rows: any[],
  orderBy:
    | {
        column: string;
        asc: boolean;
      }[]
    | undefined
): any[] | undefined => {
  if (!rows || !Array.isArray(rows) || !orderBy) return undefined;
  rows.sort((e1, e2) => {
    for (const o of orderBy) {
      const diff = `${e1[o.column]}`.localeCompare(`${e2[o.column]}`);
      if (diff !== 0) return o.asc ? diff : -diff;
    }
    return 0;
  });

  return rows;
};

export const formatDateTime = (date: Date) => {
  if (!date) return '';
  return date.toLocaleDateString('de-DE', {
    timeZone: 'UTC',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
};

export const formatDate = (date: Date) => {
  if (!date || !+date || !date.toLocaleDateString()) return null;
  return date.toLocaleDateString('de-DE', {
    timeZone: 'UTC',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  });
}

export const subtractSet = <T = any>(setA: Set<T>, setB: Set<T>) => {
  Array.from(setB).forEach(v => setA.delete(v));
  return setA;
}
