import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild
} from '@angular/core';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { MatInput } from '@angular/material/input';
import { MatMenuTrigger } from '@angular/material/menu';
import { Subscription } from 'rxjs';

import { TextFilter } from './compare/text-filter';
import { NumberFilter } from './compare/number-filter';
import { BooleanFilter } from './compare/boolean-filter';
import { AbstractFilter } from './compare/abstract-filter';
import { TableColumn } from '../../models/table-column.model';
import { isNullOrUndefined } from '../../core/types';
import { TableIntl } from '../../core/table-intl';
import { TableService } from '../../services/table.service';

const listAnimation = trigger('listAnimation', [
  transition('* <=> *', [query(':enter', [style({ opacity: 0 }), stagger('10ms', animate('400ms ease-out', style({ opacity: 1 })))], { optional: true })])
]);

@Component({
  selector: 'inf-table-column-filter',
  templateUrl: './column-filter.component.html',
  styleUrls: ['./column-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [listAnimation]
})
export class ColumnFilterComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() column?: TableColumn<any>;
  @Output() filterChanged: EventEmitter<AbstractFilter[]> = new EventEmitter<AbstractFilter[]>();

  @ContentChildren('filterInput') filterInputList!: QueryList<MatInput>;
  @ViewChild(MatMenuTrigger, { static: true }) menu!: MatMenuTrigger;

  private filterList: AbstractFilter[] = [];
  private eventsSubscription: Subscription;

  @Input()
  get filters(): AbstractFilter[] {
    if (isNullOrUndefined(this.filterList) === true || this.filterList.length === 0) {
      this.filterList = [];
      this.addNewFilter(this.column.type || 'text');
    }
    return this.filterList;
  }

  set filters(values: AbstractFilter[]) {
    this.filterList = values;
  }

  @HostBinding('class.has-value')
  get hasValue(): boolean {
    return this.filterList && this.filterList.filter(f => f.hasValue() === true).length > 0;
  }

  @HostBinding('class.show-trigger')
  get showTrigger(): boolean {
    if (this.menu === undefined) {
      return false;
    } else {
      return this.menu.menuOpen || this.hasValue;
    }
  }

  constructor(
    public languagePack: TableIntl,
    public service: TableService) {
  }

  ngOnDestroy(): void {
    if (this.eventsSubscription) {
      this.eventsSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    if (isNullOrUndefined(this.filters)) {
      this.filters = [];
      this.addNewFilter(this.column.type);
    }
  }

  addNewFilter(type: string = 'text'): AbstractFilter<any> {
    switch (type || 'text') {
      case 'text': {
        this.filterList.push(new TextFilter(this.languagePack));
        break;
      }
      case 'number': {
        this.filterList.push(new NumberFilter(this.languagePack));
        break;
      }
      case 'boolean': {
        this.filterList.push(new BooleanFilter(this.languagePack));
        break;
      }
      default:
        this.filterList.push(new TextFilter(this.languagePack));
        break;
    }

    this.filters[this.filters.length - 1].selectedIndex = 0;
    return this.filters[this.filters.length - 1];
  }

  ngAfterViewInit() {
    if (this.menu) {
      this.eventsSubscription = this.menu.menuOpened.subscribe(() => this.focusToLastInput());
    }
  }

  focusToLastInput() {
    setTimeout(() => {
      if (this.filterInputList.length > 0) {
        this.filterInputList.last.focus();
      }
    });
  }

  clearColumn_OnClick() {
    this.filterList = [];
    this.filterChanged.emit(this.filterList);
  }

  applyFilter_OnClick() {
    this.filterChanged.emit(this.filterList);
  }
}
