import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '@components/icon/icon.component';
import { RouterModule } from '@angular/router';
import { NavigationGroup } from '@core/types';

@Component({
  selector: 'inf-sub-nav',
  standalone: true,
  imports: [CommonModule, RouterModule, IconComponent],
  templateUrl: `./sub-nav.component.html`,
  styleUrl: `./sub-nav.component.scss`
})
export class SubNavComponent {
  @Input() navItems: NavigationGroup[] = [];
}
