import { AbstractControl, FormControl, ValidationErrors } from '@angular/forms';
import { AutocompleteTypeComponent } from '@components/formly/autocomplete-type.component';
import { ChipInputTypeComponent } from '@components/formly/chip-input-type.component';
import { DialogSelectTypeComponent } from '@components/formly/dialog-select-type.component';
import { FileUploadTypeComponent } from '@components/formly/file-upload-type/file-upload-type.component';
import { FormlyWrapperComponent } from '@components/formly/formly-wrapper.component';
import { FormlyNoteComponent } from '@components/formly/note-type.component';
import { RteTypeComponent } from '@components/formly/rte-type.component';
import { FormlySectionComponent } from '@components/formly/section-type.component';
import { SelectTypeComponent } from '@components/formly/select-type.component';
import { FormlySpacingComponent } from '@components/formly/spacing-type.component';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyFieldInput } from '@ngx-formly/material/input';
import { IsEmail, isPhoneNumber, isPostalCode, validateSync } from 'class-validator';
import * as moment from 'moment';
import { Moment } from 'moment';
import countryCodeMap from '../utils/country-code';
import { FormlyExpandableWrapper } from '@components/formly/exandable-wrapper.component';
import { FormlyDatePickerComponent } from '@components/formly/datepicker-type.component';
import * as dayjs from 'dayjs'

// Validators and Validator messages
const whenNonEmptyValue =
  <T>(fn: (value: T) => ValidationErrors) =>
  (control: FormControl) =>
    control.value ? fn(control.value) : null;

// PLZ
export const plzValidator = (control: FormControl, field: FormlyFieldConfig): ValidationErrors =>
  !control.value || /^\d{5}$/.test(control.value) ? null : { plz: field.formControl.value };
export const plzValidatorMessage = (plz: string) => `${plz} is nicht gültig`;

export const euPlzAdresseValidator = (
  control: AbstractControl,
  _field: FormlyFieldConfig,
  options: {
    plzKey: string;
    landKey: string;
  }
) => {
  const { plzKey, landKey } = options;
  const plzControl = control.get(plzKey);
  const plz = plzControl?.value;

  if (plz) plzControl.markAsTouched();

  const land = (control.get(landKey)?.value || 'DE') as string;
  if (plz === undefined) return null; // field currently hidden

  if (land === 'CY' && plz.length < 5) {
    return null;
  } else if (land !== 'CY' && isPostalCode(plz, land as any)) {
    return null;
  } else {
    return { 'eu-plz-adresse': { message: `Keine zulässige PLZ für Land ${land || 'DE'}` } };
  }
};

export const euPlzPostfachValidator = (
  control: AbstractControl,
  _field: FormlyFieldConfig,
  options: {
    plzKey: string;
    landKey: string;
  }
) => {
  const { plzKey, landKey } = options;
  const plzControl = control.get(plzKey);
  const plz = plzControl?.value;

  if (plz) plzControl.markAsTouched();

  const land = (control.get(landKey)?.value || 'DE') as string;
  if (plz === undefined) return null; // field currently hidden

  if (land === 'CY' && plz.length < 5) {
    return null;
  } else if (land !== 'CY' && isPostalCode(plz, land as any)) {
    return null;
  } else {
    return { 'eu-plz-postfach': { message: `Keine zulässige PLZ für Land ${land || 'DE'}` } };
  }
};

// CoName
const coRegex = /^c\W?o\s/i;
export const coNameValidator = whenNonEmptyValue((coName: string) => (coName.match(coRegex) ? { coName } : null));
export const coNameValidatorMessage = coName => `c/o Name darf nicht mit "${coName.match(coRegex)[0]}" beginnen`;

// land validator
export const landValidator = whenNonEmptyValue((land: string) => {
  let landUpperCase = land.toUpperCase();

  if (['DEUTSCHLAND', 'BRD', 'D'].includes(landUpperCase)) landUpperCase = 'DE';

  return countryCodeMap[landUpperCase] ? null : { land };
});
export const landValidatorMessage = (land: string) => `${land} is nicht gültig`;

// E-Mail
export class EmailValidatorClass {
  @IsEmail() email: string;
}

export const isValidEmail = (email: string) => {
  const emailValidatorObj = new EmailValidatorClass();
  emailValidatorObj.email = email;
  return validateSync(emailValidatorObj).length === 0;
};
export const emailValidator = (control: FormControl): ValidationErrors => (isValidEmail(control.value) ? null : { email: true });
export const emailValidatorMessage = (err, field: FormlyFieldConfig) => `"${field.formControl.value}" ist keine gültige E-Mail Adresse`;

const emailsValidator = ({ value: emails }: AbstractControl<string[]>): ValidationErrors => {
  return (emails || []).every(email => isValidEmail(email)) ? null : { emails: true };
};
export const emailsValidatorMessage = 'Bitte überprüfen Sie Ihre Eingaben';

// URL
export const urlValidator = (control: FormControl): ValidationErrors =>
  !control.value ||
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/.test(
    control.value
  )
    ? null
    : { url: true };
export const urlValidatorMessage = (err, field: FormlyFieldConfig) => `"${field.formControl.value}" ist keine gültige URL`;

// Phone Number
export const phoneValidator = whenNonEmptyValue((phone: string) => {
  return validatePhone(phone, { maxLength: 20 });
});
export const phoneValidatorMessage = (phone: string) => `"${phone}" ist ungültig`;

// Mobile Phone Number
export const mobilePhoneValidator = whenNonEmptyValue((phone: string) => {
  const validatorResult = validatePhone(phone);
  // Map to mobile property so that formly uses correct validator message
  return validatorResult ? { mobile: validatorResult.phone } : null;
});
export const mobilePhoneValidatorMessage = (mobile: string) => `"${mobile}" ist ungültig`;

const validatePhone = (phone: string, options?: { maxLength?: number }) => {
  if (options?.maxLength && phone.length > options.maxLength) return { phone };
  const cleanedPhone = phone.replaceAll(/\s/g, '');
  const startsWithPlus = cleanedPhone.startsWith('+');
  const startsWithZero = cleanedPhone.startsWith('0');
  if (!startsWithPlus && !startsWithZero) return { phone };
  const hasSpecialChars = /[^\d+]/.test(startsWithPlus ? cleanedPhone.slice(1) : cleanedPhone);
  if (hasSpecialChars) return { phone };
  const isValidGermanPhone = isPhoneNumber(cleanedPhone, 'DE');
  if (isValidGermanPhone) return null;
  if (!startsWithPlus) return { phone };
  const isValidInternationalPhone = isPhoneNumber(cleanedPhone);
  return isValidInternationalPhone ? null : { phone };
};

// IBAN
export const ibanValidator = (control: FormControl): ValidationErrors =>
  !control.value || /^DE\d{2}\s?([0-9a-zA-Z]{4}\s?){4}[0-9a-zA-Z]{2}$/.test(control.value) ? null : { iban: true };

export const ibanValidatorMessage = (err, field: FormlyFieldConfig) => `"${field.formControl.value}" ist keine gültige IBAN`;

// BIC
export const bicValidator = (control: FormControl): ValidationErrors =>
  !control.value || /^[a-z]{6}[2-9a-z][0-9a-np-z]([a-z0-9]{3}|x{3})?$/i.test(control.value) ? null : { bic: true };

export const bicValidatorMessage = (err, field: FormlyFieldConfig) => `"${field.formControl.value}" ist keine gültige BIC`;

// date not past
export const dateNotPastValidator = (control: FormControl): ValidationErrors => {
  const value = control.value as Moment;
  const now = moment().startOf('day');

  return !control.value || value.isSameOrAfter(now) ? null : { dateNotPast: true };
};
const dateNotPastValidationMessage = 'Das Datum darf nicht in der Vergangenheit liegen';

// scale
export const scaleValidator = (control: FormControl): ValidationErrors => (!control.value || /^1:\d+$/.test(control.value) ? null : { scale: true });
const scaleValidatorMessage = (err, field: FormlyFieldConfig) => `"${field.formControl.value}" ist kein gültiger Maßstab`;

// integer
export const integerValidator = (control: FormControl): ValidationErrors => (control.value?.toFixed(0) === String(control.value) ? null : { integer: true });
const integerValidatorMessage = (err, field: FormlyFieldConfig) => `"${field.formControl.value}" ist keine ganze Zahl`;

// number greater equals zero
export const numberGreaterEqualsZeroValidator = (control: FormControl): ValidationErrors => {
  if (!control.value) return null;
  const value = Number(control.value);
  return !isNaN(value) && value >= 0 ? null : { numberGreaterEqualsZero: true };
};
const numberGreaterEqualsZeroValidatorMessage = (err, field: FormlyFieldConfig) => `"${field.formControl.value}" muss eine Zahl größer oder gleich 0 sein`;

// nullable integer
export const nullableIntegerValidator = (control: FormControl): ValidationErrors => {
  if (!control.value) return null;
  const value = Number(control.value);
  return value.toFixed(0) === String(control.value) ? null : { integer: true };
};
const nullableIntegerValidatorMessage = (err, field: FormlyFieldConfig) => `"${field.formControl.value}" ist keine ganze Zahl`;

export const nonEmptyValidator = (control: FormControl): ValidationErrors => {
  if (!control.value) return null;
  return control.value.length > 0 ? null : { nonEmpty: true };
};
const nonEmptyValidatorMessage = () => `Braucht mindestens ein Wert`;

// date
export const dateFutureValidator = (
  control: AbstractControl,
  _field: FormlyFieldConfig,
  options: { days: number; baseDateKey: string; futureDateKey: string }
): ValidationErrors => {
  const { days, baseDateKey, futureDateKey } = options;
  const baseDate = control.get(baseDateKey).value;
  const selectedDate = dayjs(control.value[futureDateKey]);
  const futureDate = dayjs(baseDate).add(days, 'day');

  const baseField = _field.get(baseDateKey);
  const futureField = _field.get(futureDateKey);

  if (!control.value[futureDateKey]) {
    return null
  }

  if (selectedDate.isAfter(futureDate) || selectedDate.isSame(futureDate)) {
    return null;
  }

  return {
    'date-future': {
      message: `"${baseField.props?.label || baseDateKey}" muss mindestens ${days === 1 ? 'ein' : days} Tag${days === 1 ? '' : 'e'} nach "${futureField.props?.label || futureDateKey}" stattfinden`
    }
  };
};

// Formly Config
export const formlyConfig = {
  extras: { renderFormlyFieldElement: true, lazyRender: false },
  validators: [
    { name: 'plz', validation: plzValidator },
    { name: 'co-name', validation: coNameValidator },
    { name: 'email', validation: emailValidator },
    { name: 'url', validation: urlValidator },
    { name: 'phone', validation: phoneValidator },
    { name: 'iban', validation: ibanValidator },
    { name: 'bic', validation: bicValidator },
    { name: 'mobile', validation: mobilePhoneValidator },
    { name: 'dateNotPast', validation: dateNotPastValidator },
    { name: 'emails', validation: emailsValidator },
    { name: 'scale', validation: scaleValidator },
    { name: 'integer', validation: integerValidator },
    { name: 'nullableInteger', validation: nullableIntegerValidator },
    { name: 'numberGreaterEqualsZero', validation: numberGreaterEqualsZeroValidator },
    { name: 'eu-plz-adresse', validation: euPlzAdresseValidator },
    { name: 'eu-plz-postfach', validation: euPlzPostfachValidator },
    { name: 'land', validation: landValidator },
    { name: 'nonEmpty', validation: nonEmptyValidator },
    { name: 'date-future', validation: dateFutureValidator },
  ],
  validationMessages: [
    { name: 'required', message: 'Bitte überprüfen Sie Ihre Eingaben' },
    { name: 'coName', message: coNameValidatorMessage },
    { name: 'plz', message: plzValidatorMessage },
    { name: 'email', message: emailValidatorMessage },
    { name: 'url', message: urlValidatorMessage },
    { name: 'phone', message: phoneValidatorMessage },
    { name: 'iban', message: ibanValidatorMessage },
    { name: 'bic', message: bicValidatorMessage },
    { name: 'mobile', message: mobilePhoneValidatorMessage },
    { name: 'dateNotPast', message: dateNotPastValidationMessage },
    { name: 'emails', message: emailsValidatorMessage },
    { name: 'scale', message: scaleValidatorMessage },
    { name: 'integer', message: integerValidatorMessage },
    { name: 'numberGreaterEqualsZero', message: numberGreaterEqualsZeroValidatorMessage },
    { name: 'nullableInteger', message: nullableIntegerValidatorMessage },
    { name: 'nonEmpty', message: nonEmptyValidatorMessage }
  ],
  types: [
    { name: 'input', component: FormlyFieldInput },
    {
      name: 'autocomplete',
      component: AutocompleteTypeComponent,
      wrappers: ['form-field']
    },
    //   {
    //     name: 'button',
    //     component: FormlyButtonComponent,
    //     defaultOptions: {
    //       templateOptions: {
    //         btnType: 'mat-button'
    //       }
    //     }
    //   },
    {
      name: 'select-with-endpoint',
      component: SelectTypeComponent
    },
    {
      name: 'file-upload',
      component: FileUploadTypeComponent
    },
    {
      name: 'dialog-select',
      component: DialogSelectTypeComponent
    },
    {
      name: 'chip-input',
      component: ChipInputTypeComponent
    },
    {
      name: 'rte',
      component: RteTypeComponent
    },
    {
      name: 'section',
      component: FormlySectionComponent
    },
    {
      name: 'spacing',
      component: FormlySpacingComponent
    },
    {
      name: 'note',
      component: FormlyNoteComponent
    },
    {
      name: 'datepicker',
      component: FormlyDatePickerComponent,
      defaultOptions: { templateOptions: { datepickerOptions: { datepickerTogglePosition: 'prefix' } } }
    }
  ],
  wrappers: [
    { name: 'formly-wrapper', component: FormlyWrapperComponent },
    { name: 'expandable-wrapper', component: FormlyExpandableWrapper }
  ]
};
