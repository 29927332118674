import { Role } from '@app/models';
import { NavigationItem } from '@core/types';

export default (roles: string[]) => {
  const items: NavigationItem[] = [
    {
      id: 'dashboard',
      label: 'Dashboard',
      icon: 'fire',
      routerLink: '/dashboard',
      groups: [
        {
          name: 'Übersicht',
          items: [{ id: 'uebersicht', label: 'Übersicht', icon: 'fire', routerLink: '/dashboard/uebersicht', roles: [Role.InfrestAdmin, Role.SuperAdmin, Role.ToeBPflege, Role.ToeBFreigabe, Role.Kundenservice, Role.Diensleistung, Role.DSGVO] }]
        }
      ]
    },
    {
      id: 'vorgaenge',
      label: 'Vorgänge',
      icon: 'inbox',
      routerLink: '/vorgaenge',
      groups: [
        {
          name: 'Vorgänge',
          items: [{ id: 'all', label: 'Alle Teilvorgänge', icon: 'inbox', routerLink: '/vorgaenge/alle', roles: [Role.InfrestAdmin, Role.SuperAdmin, Role.Kundenservice] }]
        },
        {
          name: 'DL-Vorgänge',
          items: [
            { id: 'all', label: 'Alle', icon: 'inbox', routerLink: '/vorgaenge/dl-vorgaenge', roles: [Role.InfrestAdmin, Role.SuperAdmin, Role.Diensleistung] },
            {
              id: 'not-assigned',
              label: 'Nicht zugewiesene',
              icon: 'inbox',
              routerLink: '/vorgaenge/dl-vorgaenge-nicht-zugewiesene',
              roles: [Role.InfrestAdmin, Role.SuperAdmin, Role.Diensleistung]
            }
          ]
        },
        {
          name: 'Übertragungen',
          items: [
            { id: 'bsa', label: 'Baustellenatlas (BSA)', icon: 'inbox', routerLink: '/vorgaenge/bsa-uebertragung', roles: [Role.InfrestAdmin, Role.SuperAdmin] },
            { id: 'bsa', label: 'OPLEX', icon: 'inbox', routerLink: '/vorgaenge/oplex-uebertragung', roles: [Role.InfrestAdmin, Role.SuperAdmin] }
          ]
        }
      ]
    },
    {
      id: 'organisation',
      label: 'Organisation',
      icon: 'office-building',
      routerLink: '/organisation',
      groups: [
        {
          name: 'Anfrage',
          items: [
            {
              id: 'registrierte-organisation',
              label: 'Registrierte',
              icon: 'help-circle',
              routerLink: '/organisation/registrierte',
              roles: [Role.InfrestAdmin, Role.SuperAdmin, Role.Kundenservice, Role.ToeBPflege]
            },
            {
              id: 'organisation-aenderungsanfrage',
              label: 'Änderungsanfragen',
              icon: 'help-circle',
              routerLink: '/organisation/aenderungsanfragen',
              roles: [Role.InfrestAdmin, Role.SuperAdmin, Role.Kundenservice]
            }
          ]
        },
        {
          name: 'Organisationen',
          items: [
            { id: 'alle', label: 'Alle', icon: 'office-building', routerLink: '/organisation/alle', roles: [Role.InfrestAdmin, Role.SuperAdmin, Role.Kundenservice, Role.ToeBPflege] },
            { id: 'privat', label: 'Privat', icon: 'office-building', routerLink: '/organisation/privat', roles: [Role.InfrestAdmin, Role.SuperAdmin, Role.Kundenservice, Role.ToeBPflege] },
            { id: 'unternehmen', label: 'Unternehmen', icon: 'office-building', routerLink: '/organisation/unternehmen', roles: [Role.InfrestAdmin, Role.SuperAdmin, Role.Kundenservice, Role.ToeBPflege] },
            { id: 'kommune', label: 'Kommune', icon: 'office-building', routerLink: '/organisation/kommune', roles: [Role.InfrestAdmin, Role.SuperAdmin, Role.Kundenservice, Role.ToeBPflege] }
          ]
        },
        {
          name: 'Beziehungen',
          items: [
            {
              id: 'ag-an-beziehung',
              label: 'AG-AN-Beziehung',
              icon: 'link',
              routerLink: '/organisation/auftraggeber-beziehung',
              roles: [Role.InfrestAdmin, Role.SuperAdmin, Role.Kundenservice]
            }
          ]
        }
      ]
    },
    {
      id: 'toebs',
      label: 'TöB',
      icon: 'office',
      routerLink: '/toebs',
      groups: [
        {
          name: 'TöB',
          items: [
            { id: 'toebs', label: 'Alle', icon: 'office', routerLink: '/toebs/alle', roles: [Role.InfrestAdmin, Role.SuperAdmin, Role.ToeBPflege] },
            { id: 'toebs-draft', label: 'Entwurf', icon: 'office', routerLink: '/toebs/entwurf', roles: [Role.InfrestAdmin, Role.SuperAdmin, Role.ToeBPflege] },
            { id: 'toebs-freigabe', label: 'Freigabe', icon: 'office', routerLink: '/toebs/freigabe', roles: [Role.InfrestAdmin, Role.SuperAdmin, Role.ToeBFreigabe] }
          ]
        }
      ]
    },
    {
      id: 'transaktionen',
      label: 'Transaktion',
      icon: 'note',
      routerLink: '/transaktionen',
      groups: [
        {
          name: 'Transaktionen',
          items: [
            { id: 'anfrage', label: 'Anfrage', icon: 'note', routerLink: '/transaktionen/anfrage', roles: [Role.InfrestAdmin, Role.SuperAdmin] },
            { id: 'auskunft', label: 'Auskunft', icon: 'note', routerLink: '/transaktionen/auskunft', roles: [Role.InfrestAdmin, Role.SuperAdmin] }
          ]
        }
      ]
    },
    {
      id: 'nutzer',
      label: 'Nutzer',
      icon: 'account',
      routerLink: '/nutzer',
      groups: [
        {
          name: 'Übersicht',
          items: [
            { id: 'nutzer', label: 'Aktiv', icon: 'account', routerLink: '/nutzer/alle', roles: [Role.InfrestAdmin, Role.SuperAdmin, Role.Kundenservice] },
            { id: 'admin', label: 'Admin', icon: 'account', routerLink: '/nutzer/admin', roles: [Role.InfrestAdmin, Role.SuperAdmin] }
          ]
        }
      ]
    },
    {
      id: 'news',
      label: 'News',
      icon: 'newspaper-variant-outline',
      routerLink: '/news',
      groups: [
        {
          name: 'Übersicht',
          items: [
            { id: 'news', label: 'News', icon: 'newspaper-variant-outline', routerLink: '/news/alle', roles: [Role.InfrestAdmin, Role.SuperAdmin, Role.Kundenservice] },
          ]
        }
      ]
    },
    {
      id: 'einstellungen',
      label: 'Einstellung',
      icon: 'cog',
      routerLink: '/einstellungen',
      groups: [
        {
          name: 'Produkte',
          items: [
            { id: 'vorgang-produkte', label: 'Anfrage', icon: 'settings', routerLink: '/einstellungen/vorgang-produkte', roles: [Role.InfrestAdmin, Role.SuperAdmin] },
            { id: 'auskunft-produkte', label: 'Auskunft', icon: 'settings', routerLink: '/einstellungen/auskunft-produkte', roles: [Role.InfrestAdmin, Role.SuperAdmin] }
          ]
        },
        {
          name: 'SAP Produkte',
          items: [
            {
              id: 'sap-vorgang-produkte',
              label: 'Anfrage',
              icon: 'settings',
              routerLink: '/einstellungen/sap-vorgang-produkte',
              roles: [Role.InfrestAdmin, Role.SuperAdmin]
            },
            {
              id: 'sap-auskunft-produkte',
              label: 'Auskunft',
              icon: 'settings',
              routerLink: '/einstellungen/sap-auskunft-produkte',
              roles: [Role.InfrestAdmin, Role.SuperAdmin]
            }
          ]
        },
        {
          name: 'Wertetabellen',
          items: [
            { id: 'anfrageanlass', label: 'Anfrageanlass', icon: 'settings', routerLink: '/einstellungen/anfrageanlass', roles: [Role.InfrestAdmin, Role.SuperAdmin] },
            { id: 'massnahmeart', label: 'Art der Maßnahme', icon: 'settings', routerLink: '/einstellungen/massnahmeart', roles: [Role.InfrestAdmin, Role.SuperAdmin] },
            { id: 'vorgangsart', label: 'Vorgangsart', icon: 'settings', routerLink: '/einstellungen/vorgangsart', roles: [Role.InfrestAdmin, Role.SuperAdmin] },
            { id: 'dokumentart', label: 'Dokumentart', icon: 'settings', routerLink: '/einstellungen/dokumentart', roles: [Role.InfrestAdmin, Role.SuperAdmin] },
            { id: 'sparten', label: 'Sparten', icon: 'settings', routerLink: '/einstellungen/sparten', roles: [Role.InfrestAdmin, Role.SuperAdmin] }
          ]
        },
        {
          name: 'Dienst',
          items: [
            {
              id: 'kartendienst',
              label: 'Kartendienst',
              icon: 'settings',
              routerLink: '/einstellungen/kartendienst',
              roles: [Role.InfrestAdmin, Role.SuperAdmin]
            }
          ]
        },
        {
          name: 'Sonstiges',
          items: [
            {
              id: 'nutzungsbedingung',
              label: 'Nutzungsbedingung',
              icon: 'settings',
              routerLink: '/einstellungen/nutzungsbedingung',
              roles: [Role.InfrestAdmin, Role.SuperAdmin]
            }
          ]
        }
      ]
    },
    {
      id: 'aufgaben',
      label: 'Aufgaben',
      icon: 'workflow',
      routerLink: '/aufgaben',
      groups: [
        {
          name: 'Status',
          items: [
            { id: 'active', label: 'Aktiv', icon: 'workflow', routerLink: '/aufgaben/aktiv', roles: [Role.InfrestAdmin, Role.SuperAdmin] },
            { id: 'completed', label: 'Abgeschlossene', icon: 'workflow', routerLink: '/aufgaben/abgeschlossene', roles: [Role.InfrestAdmin, Role.SuperAdmin] }
          ]
        }
      ]
    }
  ];

  return items.filter(item => {
    item.groups = item.groups.filter(group => {
      group.items = group.items.filter(gi => {
        return gi?.roles?.some(r => roles.includes(r));
      });
      return group.items.length > 0;
    });
    return item.groups.length > 0;
  });
};
