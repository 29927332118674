import { HttpClient, HttpEventType, HttpProgressEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, filter, map, Observable, Subject } from 'rxjs';
import { IFile } from '../models/file';
import {environment} from "../../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  private readonly fileApiUrl = `${environment.apiUrl}api/v1/dateispeicher/vorgang`;
  private subject$ = new Subject<any>();
  private isValidated = false;

  constructor(private readonly http: HttpClient) {}

  getAllFiles$(inquiryId: string) {
    return this.http.get<IFile[]>(`${this.fileApiUrl}/${inquiryId}`);
  }

  getFile$(inquiryId: string, fileId: string): Observable<Blob> {
    return this.http.get(`${this.fileApiUrl}/${inquiryId}/${fileId}`, {
      responseType: 'blob'
    });
  }

  getFileForDownload(fileId: string) {
    return this.http.get(`${this.fileApiUrl}/${fileId}`, {
      observe: 'response',
      responseType: 'blob' as 'json'
    });
  }

  uploadVorgangFile(file: File, scopeId: string, vorgangId: string) {
    const formData = new FormData();
    formData.append('partition', `lap/vorgang/dateien`);
    formData.append('scope', scopeId);
    formData.append('file', file);
    const req = new HttpRequest('POST', this.fileApiUrl, formData, {
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req).pipe(
      filter(event => event.type === HttpEventType.UploadProgress),
      map((event: HttpProgressEvent) => ({ loaded: event.loaded, total: event.total }))
    );
  }

  updateFile$(inquiryId: string, file: IFile): Observable<any> {
    return EMPTY;
    // if (!file.hasChanged) return EMPTY;
    // return this.http.patch(`${this.fileApiUrl}/${inquiryId}/${file.id}`, {
    //   name: file.name,
    //   type: file.type.id,
    //   format: file.format.trim().replace('.', '')
    // });
  }

  deleteFile$(inquiryId: string, fileId: string): Observable<any> {
    return EMPTY;
    return this.http.delete(`${this.fileApiUrl}/${inquiryId}/${fileId}`);
  }
}
