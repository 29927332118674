import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, OnInit } from '@angular/core';
import { MatChipEditedEvent, MatChipInputEvent } from '@angular/material/chips';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';
import * as config from '../../configs';
import { SubSink } from 'subsink';

type ChipItem = { name: string };

@Component({
  template: `
    <mat-form-field [ngStyle]="{ width: props['width'] + '%' }" appearance="fill">
      <mat-label>{{ props.label }}</mat-label>
      <mat-chip-grid #chipGrid [errorStateMatcher]="errorStateMatcher" [formControl]="formControl" [required]="props.required">
        <mat-chip-row *ngFor="let item of items" (removed)="remove(item)" [editable]="true" (edited)="edit(item, $event)">
          <span [class.invalid]="!isItemValid(item)">{{ item.name }}</span>
          <button matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
        <input
          [matChipInputFor]="chipGrid"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="addOnBlur"
          (matChipInputTokenEnd)="add($event)"
          [required]="props.required"
          [placeholder]="props['newItemPlaceholder']" />
      </mat-chip-grid>
      <mat-error>
        <formly-validation-message [field]="field"></formly-validation-message>
      </mat-error>
    </mat-form-field>
  `,
  styles: [
    `
      @import '../../../styles/base/_colors';

      .invalid {
        color: $infrest-warn-color;
      }
    `
  ]
})
export class ChipInputTypeComponent extends FieldType<FieldTypeConfig> implements OnInit {
  items: ChipItem[] = [];
  addOnBlur = true;
  private readonly subs = new SubSink();

  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  constructor() {
    super();
  }

  ngOnInit() {
    this.items = this.model[this.key as string]?.map((i: ChipItem) => ({ name: i })) || [];
    this.subs.sink = this.formControl.valueChanges.subscribe(() => {
      this.items = this.model[this.key as string]?.map((i: ChipItem) => ({ name: i })) || [];
    });
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.subs.unsubscribe();
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value) {
      this.items.push({ name: value });
      this.formControl.setValue(this.items.map(i => i.name));
      this.formControl.markAsTouched();
    }

    // Clear the input value
    event.chipInput!.clear();
  }

  remove(item: ChipItem): void {
    const index = this.items.indexOf(item);

    if (index >= 0) {
      this.items.splice(index, 1);
      this.formControl.setValue(this.items.map(i => i.name));
      this.formControl.markAsTouched();
    }
  }

  edit(item: ChipItem, event: MatChipEditedEvent) {
    const value = event.value.trim();

    if (!value) {
      this.remove(item);
      return;
    }

    const index = this.items.indexOf(item);
    if (index >= 0) {
      this.items[index].name = value;
    }
    this.formControl.setValue(this.items.map(i => i.name));
    this.formControl.markAsTouched();
  }

  isItemValid(item: ChipItem) {
    return (config[this.props['itemValidator']] || (() => true))(item.name);
  }
}
