import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { SubSink } from 'subsink';
import * as Rx from 'rxjs';

@Component({
  selector: 'inf-formly-select-type',
  template: `
    <mat-form-field appearance="fill" (click)="onClick()">
      <mat-label>{{ props.label }}{{props.required ? ' *' : ''}}</mat-label>
      <mat-select #selectComponent [formControl]="formControl" [multiple]="props['multiple']">
        <mat-option *ngFor="let option of selectOptions | async" [value]="option.value" >{{ option.label }}</mat-option>
      </mat-select>
    </mat-form-field>
  `
})
export class SelectTypeComponent extends FieldType<FieldTypeConfig> implements OnInit {
  selectOptions = new Rx.BehaviorSubject([]);

  @ViewChild('selectComponent') selectComponent;

  constructor(private http: HttpClient) {
    super();
  }

  private readonly _subs = new SubSink();

  public onClick() {
    this._subs.sink = this.getOptions().subscribe((res) => {
      this.selectOptions.next(res.options);
      // Fix for having to click twice before options pane opens
      setTimeout(() => this.selectComponent.open(), 0);
    });
  }

  ngOnInit() {
    const initialModelValue = this.model[this.key as string];

    //set options and default value initially
    this.http.post<OptionResponse>(`${environment.apiUrl}${this.props['endpoint']}`, {}).subscribe(res => {
      this.selectOptions.next(res.options);
      if (initialModelValue) {
        //ignore defaultValues if values are set in model
        this.props['multiple'] ? this.formControl.setValue(initialModelValue) : this.formControl.setValue(Array.isArray(initialModelValue) ? initialModelValue[0] : initialModelValue);
      } else {
        this.setDefaultValues(res.defaultValue);
      }
    });
  }

  private getOptions() {
    // Always clear last options before fetching new options.
    // this.selectOptions.next([]);
    // Don't get options if field is disabled.
    if (this.props.disabled) {
      return Rx.of({ options: [], defaultValue: [] });
    }

    // Append values from model to endpoint call body
    let body = {};
    /* Example 
    {
      "key": "wms-bild-format",
      "type": "select-with-endpoint",
      "props": {
        "label": "WMS Bildformat",
        "endpoint": "api-admin/v1/toeb/wms-capability/options",
        "endpointModelBodyMapping": {
          "username": "wms-benutzername",
          "password": "wms-passwort",
          "baseUrl": "wms-url",
          "version": "wms-version"
        },
        "endpointFixedArguments": {
          "capability": "imageFormat"
        }
      },
      "className": "field"
    },
    */
    const endpointModelBodyMapping = this.props['endpointModelBodyMapping'];
    if (endpointModelBodyMapping) {Object.assign(body, this.buildEndpointBody(endpointModelBodyMapping, this.model))}
    const endpointFixedArguments = this.props['endpointFixedArguments'];
    if (endpointFixedArguments) {Object.assign(body, endpointFixedArguments)};

    return this.http.post<OptionResponse>(`${environment.apiUrl}${this.props['endpoint']}`, body).pipe(
      Rx.map(res => {
        return res;
      }),
      Rx.catchError((_: Error) => {
        return Rx.of({ options: [], defaultValue: [] });
      })
    );
  }

  private buildEndpointBody(mapping, model) {
    const body = {};
    Object.entries(model).forEach(([fieldname,fieldvalue]) => {
      const mappingForThisField = Object.entries(mapping).find(([k,v]) => v === fieldname);
      if (!mappingForThisField) return;
      const argumentName = mappingForThisField[0];
      body[argumentName] = fieldvalue;
    })
    return body;
  }

  setDefaultValues(values: string[]) {
    if (this.props['multiple']) {
      this.formControl.setValue(values);
    } else {
      if (values) {
        this.formControl.setValue(Array.isArray(values) ? values[0] : values);
      }
    }
  }
}

interface OptionItem {
  label: string;
  value: string;
}

interface OptionResponse {
  options: OptionItem[];
  defaultValue: string[];
}
