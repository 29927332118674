import { Component, OnInit } from '@angular/core';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';


@Component({
  selector: 'inf-formly-rte-type',
  template: `
    <div class="container" [ngClass]="disabled ? 'disabled' : ''">
      <p *ngIf="props['label']" class="label">{{ props['label'] }}</p>
      <quill-editor class="editor" 
        [modules]="quillConfiguration" 
        [formControl]="formControl" 
        [styles]="{ 'background-color': 'whitesmoke', 'font-size': '14px', 'color': '#000000DE', 'font-family': 'roboto, sans-serif' }" 
        [placeholder]="props['placeholder']"></quill-editor>
    </div>
  `,
  styles: [`
    .container {
        margin-bottom: 12px;
    }

    .disabled {
        pointer-events: none;
        opacity: 0.5;
    }

    .label {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 8px;
    }
  `]
})
export class RteTypeComponent extends FieldType<FieldTypeConfig> {
  quillConfiguration = {
    toolbar: [
      ['bold', 'italic', 'underline', 'link']
    ]
  }
}