import { MISSING_NUMBER, MISSING_STRING } from "./constants";

export function coerceNumber(value: any, fallback: number = MISSING_NUMBER): number {
  return typeof value === 'number' ? value : fallback;
}

export function coerceString(value: any, fallback: string = MISSING_STRING): string {
  return typeof value === 'string' ? value: fallback;
}

export function coerceArray<T>(value: any): T[] {
  return Array.isArray(value) ? value:  [];
}

export function coerceReadonlyArray<T>(value: any): readonly T[] {
  return coerceArray(value);
}
