import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MsalService } from '@azure/msal-angular';
import { NavigationService } from '@core/services';

@Component({
  selector: 'inf-app-header',
  standalone: true,
  imports: [CommonModule, MatMenuModule, MatToolbarModule, MatIconModule],
  template: `
    <mat-toolbar color="primary">
      <div class="toolbar">
        <div class="left">
          <mat-icon class="menu-icon" (click)="onMenuToggle()" *ngIf="!displayOnly">menu</mat-icon>
        </div>
        <!--      <span class="spacer"></span>-->

        <img alt="infrest logo" class="logo" src="../../assets/images/logos/infrest-cockpit.svg" />
        <div class="right">
          <mat-icon class="user-menu" [matMenuTriggerFor]="userMenu">account_circle_outline</mat-icon>
        </div>
      </div>
      <mat-menu #userMenu="matMenu">
        <button mat-menu-item (click)="logout()">
          <mat-icon>logout</mat-icon>
          <span>Ausloggen</span>
        </button>
      </mat-menu>
    </mat-toolbar>
  `,
  styles: [
    `
      @use '../../../styles/base' as inf;

      mat-toolbar {
        padding: 0;
      }

      .toolbar {
        --toolbar-height: 3.5rem;
        --toolbar-action-left: 5rem;
        --toolbar-action-right: 5rem;

        width: 100%;
        display: grid;
        grid-template-columns: var(--toolbar-action-left) 1fr var(--toolbar-action-right);
        gap: 1rem;
        align-items: center;
        height: var(--toolbar-height);
        border-bottom: 1px solid var(--border-color);

        .left {
          display: flex;
          justify-content: center;

          .menu-icon {
            &:hover {
              cursor: pointer;
            }
          }
        }

        .logo {
          height: 32px;
        }

        .right {
          display: flex;
          justify-content: center;

          .user-menu {
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppHeaderComponent {
  @Input() displayOnly: boolean = false;
  @Output() readonly toggleMenu = new EventEmitter<void>();

  authService = inject(MsalService);
  navService = inject(NavigationService);

  onMenuToggle() {
    this.navService.state.toggleNav();
  }

  logout() {
    this.authService.logout();
  }
}
