import { Component, OnInit } from '@angular/core';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';
import { EMPTY, Observable } from 'rxjs';
import { debounceTime, map, startWith, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'inf-formly-autocomplete-type',
  // standalone: true,
  // imports: [CommonModule, MatInputModule, MatAutocompleteModule, ReactiveFormsModule, FormlyModule],
  template: `
    <input matInput [matAutocomplete]="auto" [formControl]="formControl" [formlyAttributes]="field" [placeholder]="props.placeholder" />
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displaySelectedOption" (optionSelected)="changeSelected($event, $event.option.value)">
      <mat-option *ngFor="let option of filter | async" [value]="option">
        {{ option.label }}
      </mat-option>
    </mat-autocomplete>
  `
})
export class AutocompleteTypeComponent extends FieldType<FieldTypeConfig> implements OnInit {
  filter: Observable<any>;

  ngOnInit() {
    const initialModelValue = this.model[this.key as string];
    this.setSelectedOption(initialModelValue);

    this.formControl.valueChanges.subscribe(() => {
      this.props['clearSubfields']();
    });

    this.filter = this.formControl.valueChanges.pipe(
      startWith(initialModelValue?.label ?? ''),
      debounceTime(1000),
      switchMap((term: any) => {
        if (!this.props?.['filter']) {
          return EMPTY;
        }
        return this.props?.['filter'](term);
      }),
      map((termArray: OptionItem[]) => termArray.sort((a, b) => a.label.localeCompare(b.label))),
      tap(term => console.debug(`autocomplete filter: ${JSON.stringify(term)}`))
    );
  }

  setSelectedOption(option?: OptionItem) {
    this.formControl.setValue(option);
  }

  changeSelected($event, option: any) {
    this.props['onChangeSelected'](option);
  }

  displaySelectedOption(option?: OptionItem) {
    return option ? option.label : '';
  }
}

interface OptionItem {
  label: string;
  value: string;
}
