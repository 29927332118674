import { CommonModule } from "@angular/common";
import { Component, Inject, Input } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar";

@Component({
    selector: 'inf-snackbar',
    standalone: true,
    imports: [CommonModule, MatIconModule, MatButtonModule],
    template: `
      <div [ngClass]="['container', data.success? 'success' : 'error' ]">
        <mat-icon *ngIf="data.success">check</mat-icon>
        <span >{{data.message}}</span>
        <button (click)="close()" mat-button [ngClass]="data.success? 'green' : 'red'">{{ data.buttonLabel || 'Schließen'}}</button>
      </div>
    `,
    styles: [`
      @use '../../../styles/base' as inf;
      
      .container {
        display: flex;
        align-items: center;

        span {
          margin: 0 11px;
        }

        button {
          margin-left: auto;
        }

        .green {
          color: inf.$infrest-success-color;
        }

        .red {
          color: inf.$infrest-error-color;
        }
      }
    `]
  })
  export class SnackbarComponent {
    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: { message: string, success: boolean, buttonLabel?: string }, private _snackRef: MatSnackBarRef<SnackbarComponent>) {}
    
    close() {
      this._snackRef.dismiss();
    }
  }