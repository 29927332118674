import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SnackbarComponent } from "../components/snackbar/snackbar.component";

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  constructor(private matSnackbar: MatSnackBar) {}

  open(message: string, success: boolean, duration?: number, buttonLabel?: string) {
    return this.matSnackbar.openFromComponent(SnackbarComponent, {
      data: { message, success, buttonLabel },
      panelClass: success ? ['success'] : ['error'],
      duration
    })
  }
}