<div class="inf-mat-table">
  <mat-card class="inf-table-card" appearance="outlined">
    <div class="table-wrapper" [style.height.px]="height">
      <cdk-virtual-scroll-viewport
        #tbl
        [ngStyle]="{'background-color': 'white'}"
        [ngClass]="viewportClass"
        [tvsItemSize]="rowHeight || 40"
        [headerHeight]="headerHeight || 40">
        <table mat-table
          matSort multiTemplateDataRows
          [trackBy]="indexTrackFn" [dataSource]="tvsDS"
          class="table">

          <!-- Select Checkbox Column -->
          <ng-container cdkColumnDef="row-checkbox">
            <!-- HEADER -->
            <th mat-header-cell *cdkHeaderCellDef class="row-checkbox" style="z-index: 2">
              <mat-checkbox
                style="z-index: 10"
                (change)="$event ? masterToggle() : null"
                [checked]="rowSelectionModel.hasValue() && isAllSelected()"
                [indeterminate]="rowSelectionModel.hasValue() && !isAllSelected()"
                *ngIf="rowSelectionMode === 'multiple'"></mat-checkbox>
            </th>
            <!-- DATA -->
            <td mat-cell *cdkCellDef="let row" class="row-checkbox">
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="onRowSelectionChange($event, row)"
                [checked]="rowSelectionModel?.isSelected(row)"></mat-checkbox>
            </td>
          </ng-container>

          <!-- Table Columns -->
          <ng-container
            *ngFor="let column of columns; let i = index; trackBy: trackColumn"
            [sticky]="column.sticky === 'start' ? true : false"
            [stickyEnd]="column.sticky === 'end' ? true : false"
            [cdkColumnDef]="column.key">
            <th mat-header-cell
              *cdkHeaderCellDef [ngClass]="headerClass(column)"
              [ngStyle]="column.style">
              <inf-table-column-filter
                [column]="column"
                (filterChanged)="filter_onChanged(column, $event)"
                [filters]="tvsDS.getFilter(column.key)">
                <div mat-sort-header
                     [matTooltip]="column.title"
                     matTooltipClass="cell-tooltip"
                     [disabled]="column.sort === 'none'"
                     class="header-caption">
                  {{ column.title }}
                </div>
              </inf-table-column-filter>
            </th>
            <td mat-cell #cell *cdkCellDef="let row; index as rowIndex"
              (mouseenter)="tooltip_onChanged(column, row, cell,true)"
              (mouseleave)="tooltip_onChanged(column, row, cell,false)"
              class="inf-cell"
              (click)="onCellClick($event, row, column)"
              [class]="row[column.cellClass]"
              [ngClass]="cellClass(row?.option, column)"
              [ngStyle]="cellStyle(row?.option, column)"
              (contextmenu)="onContextMenu($event, row, column)">
              <ng-container *ngIf="resolvePath(row, column.key) !== undefined">
                <ng-container *ngIf="!column.template">
                  <label *ngIf="!column.dynamicCellComponent && !column.pipes"
                         [ngStyle]="ellipsis(column)">{{resolvePath(row, column.key)}}</label>
                  <label *ngIf="!column.dynamicCellComponent && column.pipes"
                         [ngStyle]="ellipsis(column)">{{resolvePath(row, column.key) | dynamicPipe:column.pipes}}</label>
                  <ng-container
                    *ngIf="column.dynamicCellComponent" dynamicCell
                    [component]="column.dynamicCellComponent"
                    [column]="column" [row]="row" [onRowEvent]="onRowEvent">
                  </ng-container>
                </ng-container>
              </ng-container>
            </td>
          </ng-container>

          <ng-container cdkColumnDef="progress">
            <th mat-header-cell *cdkHeaderCellDef [attr.colspan]="displayedColumns.length">
              <mat-progress-bar mode="indeterminate" [class.show]="pending"></mat-progress-bar>
            </th>
          </ng-container>

          <!-- Table Menu[ Sort, Visible, Export] -->
          <ng-container cdkColumnDef="table-menu" [stickyEnd]="true">
            <!--            *ngIf="setting?.visibleTableMenu !== false"-->
            <th mat-header-cell *cdkHeaderCellDef class="table-menu">
              <inf-table-menu
                [visible]="setting?.visibleHeaderMenu"
                [(tableSetting)]="tableSetting"
                (menuActionChange)="tableMenuActionChange($event)"></inf-table-menu>
              <ng-container *ngIf="setting?.visibleTableMenu === false"></ng-container>
            </th>
            <td mat-cell *cdkCellDef="let row" class="table-menu">
              <inf-table-row-menu
                *ngIf="rowContextMenuItems && rowContextMenuItems.length > 0"
                [rowActionMenu]="row?.actionMenu"
                [row]="row"
                [tableSetting]="tableSetting"
                [actionMenus]="rowContextMenuItems"
                (rowActionChange)="rowMenuActionChange($event, row)"></inf-table-row-menu>
            </td>
          </ng-container>

          <tr mat-header-row
            class="header"
            [style.top.px]="inverseOfTranslation"
            *cdkHeaderRowDef="displayedColumns; sticky: sticky"></tr>

          <tr mat-row
            *cdkRowDef="let row; columns: displayedColumns;"
            [style.height.px]="rowHeight"
            [ngClass]="row?.option?.class"
            [class.selected]="rowSelectionModel? rowSelectionModel.isSelected(row): false"
            [ngStyle]="rowStyle(row)"
            (contextmenu)="onContextMenu($event, null, row)"
            [class.disabledRow]="row?.disabled">
          </tr>

          <tr mat-header-row
            class="progress" *cdkHeaderRowDef="progressColumn; sticky: sticky"
            [style.top.px]="inverseOfTranslation + headerHeight">
          </tr>
        </table>
      </cdk-virtual-scroll-viewport>
    </div>
    <mat-paginator
      *ngIf="paginationMode !== 'none'"
      class="paginator"
      (page)="pagination_onChange($event)"
      [length]="pagination?.length"
      [pageSize]="pagination?.pageSize"
      [pageSizeOptions]="pagination?.pageSizeOptions"></mat-paginator>
  </mat-card>

  <ng-container *ngIf="showNoData && init === true">
    <div class="no-records" *ngIf="tvsDS.data?.length === 0">{{ tableLanguage?.tableLabels?.NoData }}</div>
  </ng-container>
</div>
