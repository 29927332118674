import { Environment } from "@app/models";

export const environment: Environment = {
  production: false,
  apiUrl: 'https://dev.lap.api.portal.infrest.de/',
  apiKey: 'ea561e14449e43129d3dead209aecf57',
  portalUrl: 'https://dev.portal.infrest.de/',
  importNutzerTemplate: 'https://dev-static.portal.infrest.de/docs/Import-Nutzern.xlsx',
  oauth: {
    identityProvider: 'https://infrestcustomersdev.b2clogin.com/32dfe5f8-48ea-4860-8986-eacd65217e23/v2.0/',
    clientId: 'cecb2470-20e3-44c6-8dbd-39d5fa7f014d',
    b2cTenant: 'infrestcustomersdev',
    flowSignInSignUp: 'b2c_1_sign_in_and_up',
    flowPasswordReset: 'b2c_1_password_reset'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
