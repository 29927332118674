import { Pipe, PipeTransform } from '@angular/core';

const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

@Pipe({
  name: 'formatFileSize'
})
export class FormatFileSizePipe implements PipeTransform {
  transform(bytes: number, decimalPlaces = 2): string {
    if (bytes === 0) {
      return '0 Bytes';
    }

    const k = 1024;
    const dm = decimalPlaces < 0 ? 0 : decimalPlaces;

    let tempBytes = bytes;
    let i = 0;
    while (tempBytes > 1000 && i < sizes.length) {
      tempBytes = tempBytes / k;
      i++;
    }

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)).toLocaleString('de-de') + ' ' + sizes[i];
  }
}
