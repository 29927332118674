import {IconType} from "@components/icon/icon.component";
import { Role } from "./roles.type";

export interface NavigationItem {
  id: string;
  label: string;
  icon: IconType;
  routerLink?: string;
  command?: () => void;
  groups?: NavigationGroup[];
  roles?: Role[]
  // items?: NavigationItem[];
}

export interface NavigationGroup {
  name: string;
  items: NavigationItem[];
}
