import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-spacing',
  template: `
    <div style="margin-top: 16px"></div>
  `
})
export class FormlySpacingComponent extends FieldType {}
