import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'inf-formly-wrapper',
  template: `
    <div>
      <h3 class="mat-subheading-2">{{ to.label }}</h3>
      <ng-container #fieldComponent></ng-container>
      <hr />
    </div>
  `
})
export class FormlyWrapperComponent extends FieldWrapper {}
