import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, ValidatorFn } from '@angular/forms';

@Directive({
  selector: '[infCustomValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: CustomValidatorDirective, multi: true }]
})
export class CustomValidatorDirective {
  @Input()
  public appCustomValidator!: ValidatorFn;

  public validate(control: AbstractControl): ValidationErrors | null {
    return this.appCustomValidator(control);
  }
}
