import { Pipe, PipeTransform } from '@angular/core';
import { filterByPropertyValue } from '../utils';

@Pipe({
  name: 'filterByPropertyValue',
  standalone: true
})
export class FilterByPropertyValuePipe implements PipeTransform {

  transform<T extends Record<string, any>>(array: T[], property: keyof T, toRemove:T[keyof T][]): T[] {
    return filterByPropertyValue(array, property, toRemove)
  }
}
