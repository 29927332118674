import { Observable } from 'rxjs';
import { DynamicTableData } from './dynamic-table-data.model';

export interface DynamicDataQuery {
  term?: string;
  pageIndex?: number;
  pageSize?: number;
  orderBy?: {
    column: string;
    asc: boolean;
  }[];
  columnFilters?: Array<ColumnFilter>; // TODO
  columns?: string[];
}

export interface DynamicDataService<T = unknown> {
  getData(query: DynamicDataQuery): Observable<DynamicTableData<T>>;
}

export interface ColumnFilter {
  column: string;
  term: string;
  operator: string;
  not: boolean;
}
