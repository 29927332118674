import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'inf-unauthorized',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div class="inf-unauthorized">
      <h1>Unauthorized:</h1>
      <h3>Bitte vergewissern Sie sich, dass Ihrem Konto die Rolle eines Administrators zugewiesen ist, und melden Sie sich dann ab und erneut an.</h3>
    </div>
  `,
  styles: [
    `
      @use '../../../styles/base' as inf;

      .inf-unauthorized {
        margin: 16px;
        padding: 16px;
        border: 1px solid inf.$infrest-standard-border-color;
        background-color: inf.$infrest-warning-background-color;
        border-radius: 10px;

        h1 {
          color: inf.$infrest-error-color-dark;
        }

        h3 {
          color: inf.$infrest-error-color;
        }
      }
    `
  ]
})
export class UnauthorizedComponent {}
