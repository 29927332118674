import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'inf-expandable-wrapper',
  template: `
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ to.label }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container #fieldComponent></ng-container>
    </mat-expansion-panel>
  `
})
export class FormlyExpandableWrapper extends FieldWrapper {}
